import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { markNotAuthenticated, signInUserToken } from "./redux/actions/authActions";
import DashboardScreen from "./routes/Dashboard";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import LoginScreen from "./routes/Login";
import RegisterScreen from "./routes/Register";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";
import SubscribeScreen from "./routes/Subscribe";
import SuccessPage from "./routes/Subscribe/SuccessPage";
import LinkGoogle from "./routes/LinkGoogle";
import ConfirmLink from "./routes/LinkGoogle/ConfirmLink";
import SelectAccount from "./routes/LinkGoogle/SelectAccount";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Settings from "./routes/Settings";
import SecuritySettings from "./routes/Settings/SecuritySettings";
import SubscriptionSettings from "./routes/Settings/SubscriptionSettings";
import AdminPanel from "./routes/Admin";
import ViewDashboard from "./routes/Admin/components/ViewDashboard";
import ForgotPassword from "./routes/ForgotPassword";
import RecommendationsScreen from "./routes/Admin/RecommendationsScreen";
import LogRocket from "logrocket";
import ToDoScreen from "./routes/ToDo";
import CompanySettings from "./routes/Settings/CompanySettings";
import InviteScreen from "./routes/Invite";
import Dashboard from "./features/dashboard/routes/Dashboard";

if (process.env.NODE_ENV !== "development") {
	LogRocket.init("vci7hq/qualiscore-monitoring");
}

export default function App() {
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	LogRocket.identify(auth?.user?.id, {
		name: auth?.user?.custName,
		email: auth?.user?.custEmail,
		// Insert custom properties called "User Traits" below
		subscriptionType: auth?.user?.companyDetails?.subscriptionDetails?.packageDetails?.packageName,
	});

	useEffect(() => {
		if (auth.signedIn === null) {
			const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
				if (user) {
					dispatch(signInUserToken(user.accessToken));
				} else {
					dispatch(markNotAuthenticated());
				}
			});
			unsubscribe();
		}
	}, [auth, dispatch]);

	return (
		<BrowserRouter>
			{auth.signedIn === null ? (
				<div className='flex flex-col w-full h-screen justify-center items-center'>
					<LoadingSpinner />
					<p className='mt-8 text-2xl'>Loading details</p>
				</div>
			) : auth.signedIn ? (
				<>
					{auth.user?.companyDetails?.subscriptionId ? (
						// Screens for authenticatedusers with a subscription
						<Routes>
							{auth.user?.companyDetails?.isAdminCompany && (
								<>
									<Route path='/admin/users' element={<AdminPanel />} />
									<Route path='/admin/recommendations' element={<RecommendationsScreen />} />
									<Route path='/admin/dashboards'>
										<Route path=':userId' element={<ViewDashboard />} />
									</Route>
								</>
							)}

							<Route path='/tasks' element={<ToDoScreen />} />
							<Route path='/settings' element={<Settings />} />
							<Route path='/settings/account' element={<SecuritySettings />} />
							{auth.user?.companyAdmin && (
								<Route path='/settings/company' element={<CompanySettings />} />
							)}
							{auth.user?.companyAdmin && (
								<Route path='/settings/subscription' element={<SubscriptionSettings />} />
							)}
							{/* Routes if google is linked */}
							{auth.user?.companyDetails?.googleLinked && (
								<Route path='/' element={<Dashboard />} />
							)}
							{/* Routes if google not linked */}
							{!auth.user?.companyDetails?.googleLinked && (
								<Route path='/' element={<LinkGoogle />} />
							)}
							{!auth.user?.companyDetails?.googleLinked && (
								<Route path='/oauthcallback' element={<ConfirmLink />} />
							)}
							{!auth.user.companyDetails.googleLinked && (
								<Route path='/account-selection' element={<SelectAccount />} />
							)}
							<Route path='*' element={<Navigate to='/' />} />
						</Routes>
					) : (
						// Screen for authenticated users with no subscription
						<Routes>
							<Route path='/' element={<SubscribeScreen />} />
							<Route path='/complete' element={<SuccessPage />} />
							<Route path='*' element={<Navigate to='/' />} />
						</Routes>
					)}
				</>
			) : (
				<Routes>
					<Route path='/' element={<LoginScreen />} />
					<Route path='/register' element={<RegisterScreen />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/invite'>
						<Route path=':inviteId' element={<InviteScreen />} />
					</Route>
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			)}
			<ToastContainer autoClose={2000} position='bottom-left' />
		</BrowserRouter>
	);
}
