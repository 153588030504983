import React, { useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

function PageRankChart({ scores, setFilteredUrls, urls, setActiveFilter }) {
	const chartRef = useRef();

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				grace: "33%",
			},
		},
	};

	ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

	const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9];

	const data = {
		labels,
		datasets: [
			{
				label: "Page Ranks by Volume",
				data: scores.map((score) => score.volume),
				backgroundColor: "#00BC9C",
				hoverBackgroundColor: "#295D6F",
			},
		],
	};

	const onClickHandler = (event) => {
		const clickLocation = getElementAtEvent(chartRef.current, event);
		const scoreClicked = clickLocation[0]?.index + 1;

		const filter = (score) => {
			switch (score) {
				case 1:
					return "scoreOne";
				case 2:
					return "scoreTwo";
				case 3:
					return "scoreThree";
				case 4:
					return "scoreFour";
				case 5:
					return "scoreFive";
				case 6:
					return "scoreSix";
				case 7:
					return "scoreSeven";
				case 8:
					return "scoreEight";
				case 9:
					return "scoreNine";
				default:
					return "";
			}
		};
		if (scoreClicked) {
			const newFilteredUrls = urls.filter((url) => url.score === scoreClicked);
			setFilteredUrls(newFilteredUrls);
			setActiveFilter(filter(scoreClicked));
		} else {
			setFilteredUrls(urls);
			setActiveFilter("");
		}
	};
	return <Bar ref={chartRef} options={options} data={data} onClick={onClickHandler} />;
}

export default PageRankChart;
