import * as t from "../constants/authConstants";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import { firebaseAuth } from "../../firebase";
import axios from "axios";
import { mapAuthCodeToMessage } from "../../utils/firebaseAuthErrorHandling";
import { toast } from "react-toastify";

// Create a new firebase user and then database user
export const createNewUser = (newUser) => async (dispatch) => {
	dispatch({ type: t.ACTION_LOADING_AUTH });
	createUserWithEmailAndPassword(firebaseAuth, newUser.email, newUser.password)
		.then(async (userCredential) => {
			const userToken = userCredential.user.accessToken;
			try {
				const { data } = await axios.post(
					`${process.env.REACT_APP_BACKEND}/auth`,
					{
						newUser,
					},
					{
						headers: {
							Authorization: "Bearer " + userToken,
						},
					}
				);
				dispatch({
					type: t.SIGN_IN_USER,
					payload: data,
				});
				dispatch({ type: t.ACTION_RESET_AUTH });
			} catch (error) {
				console.log("Error occured");
				toast.error("Server Auth Error");
				dispatch({ type: t.ACTION_FAILED_AUTH });
			}
		})
		.catch((error) => {
			const message = mapAuthCodeToMessage(error.code);
			console.log(message === "" ? error.code : message);
			toast.error(message === "" ? error.code : message);
			dispatch({ type: t.ACTION_FAILED_AUTH });
		});
};

// Create a new firebase user for invited user and then updates backend to add firebaseId to user in system database
export const createInvitedUser =
	(invitedUser, password) => async (dispatch) => {
		dispatch({ type: t.ACTION_LOADING_AUTH });
		createUserWithEmailAndPassword(
			firebaseAuth,
			invitedUser.userDetails.email,
			password
		)
			.then(async (userCredential) => {
				const userToken = userCredential.user.accessToken;
				try {
					const { data } = await axios.post(
						`${process.env.REACT_APP_BACKEND}/auth/invite`,
						{
							invitedUser,
						},
						{
							headers: {
								Authorization: "Bearer " + userToken,
							},
						}
					);
					dispatch({
						type: t.SIGN_IN_USER,
						payload: data,
					});
					dispatch({ type: t.ACTION_RESET_AUTH });
				} catch (error) {
					console.log("Error occured");
					toast.error("Server Auth Error");
					dispatch({ type: t.ACTION_FAILED_AUTH });
				}
			})
			.catch((error) => {
				const message = mapAuthCodeToMessage(error.code);
				console.log(message === "" ? error.code : message);
				toast.error(message === "" ? error.code : message);
				dispatch({ type: t.ACTION_FAILED_AUTH });
			});
	};
// Sign in user with email and password to firebase and then retrieve user from database

export const signInUserEmailAndPassword =
	({ email, password }) =>
	async (dispatch) => {
		signInWithEmailAndPassword(firebaseAuth, email, password)
			.then(async (userCredential) => {
				var userToken = userCredential.user.accessToken;
				try {
					const { data } = await axios.get(
						`${process.env.REACT_APP_BACKEND}/auth/`,
						{
							headers: {
								Authorization: "Bearer " + userToken,
							},
						}
					);
					dispatch({
						type: t.SIGN_IN_USER,
						payload: data,
					});
				} catch (error) {
					console.log(error);
				}
			})
			.catch((error) => {
				const message = mapAuthCodeToMessage(error.code);
				console.log(message === "" ? error.code : message);
				toast.error(message === "" ? error.code : message);
			});
	};

// If firebase authentication already present retrieve database user
export const signInUserToken = (token) => async (dispatch) => {
	try {
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/auth/`, {
			headers: {
				Authorization: "Bearer " + token,
			},
		});
		dispatch({
			type: t.SIGN_IN_USER,
			payload: data,
		});
	} catch (error) {
		console.log(error);
		// clears firebase authentication if error retrieving database user
		dispatch(signOutUser());
	}
};

// Sign out user from firebase and clear user state from redux
export const signOutUser = () => async (dispatch) => {
	signOut(firebaseAuth)
		.then(() => {
			dispatch({
				type: t.SIGN_OUT_USER,
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// Amends signedIn from null to false. Used following app checking for token on render, null state used to support loading spinner
export const markNotAuthenticated = () => (dispatch) => {
	dispatch({
		type: t.NOT_AUTHENTICATED,
	});
};
