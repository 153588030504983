import * as t from "../constants/toDoConstants";

export const toDoReducer = (
  state = {
    toDo: null,
    loaded: false,
    actionStatus: null,
  },
  action
) => {
  switch (action.type) {
    case t.GET_TODO:
      return {
        ...state,
        toDo: action.payload,
        loaded: true,
      };

    case t.CREATE_TODO:
      return {
        ...state,
        toDo: null,
        loaded: false,
      };

    case t.COMPLETED_TODO:
      const existingFilter = state.toDo.filter(
        (todo) => todo.id !== action.payload.id
      );
      return {
        ...state,
        toDo: [...existingFilter, action.payload],
        loaded: true,
      };

    case t.DELETE_TODO:
      const filterToDos = state.toDo.filter(
        (toDo) => toDo.id !== action.payload.id
      );
      return {
        ...state,
        toDo: filterToDos,
        loaded: true,
      };

    case t.ACTION_LOADING_TODO:
      return {
        ...state,
        actionStatus: "LOADING",
      };

    case t.ACTION_COMPLETE_TODO:
      return {
        ...state,
        actionStatus: "COMPLETE",
      };

    case t.ACTION_FAILED_TODO:
      return {
        ...state,
        actionStatus: "FAILED",
      };

    case t.ACTION_RESET_TODO:
      return {
        ...state,
        actionStatus: null,
      };

    default:
      return state;
  }
};
