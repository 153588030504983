import React from "react";
import ActiveNavButton from "./ui/ActiveNavButton";
import { useSelector } from "react-redux";

export default function NavigationMenu() {
	const auth = useSelector((state) => state.auth);
	const user = auth.user;

	return (
		<div className='flex flex-col grow'>
			<ActiveNavButton to='/' label='Dashboard' />
			<ActiveNavButton to='/tasks' label='Score Optimization' />
			<ActiveNavButton to='/settings/account' label='Settings' />
			{user.companyDetails.isAdminCompany && <ActiveNavButton to='/admin/users' label='Admin' />}
		</div>
	);
}
