import React from "react";

export default function Gauge({ percent, radius }) {
	const strokeWidth = radius * 0.2;
	const innerRadius = radius - strokeWidth / 2;
	const circumference = innerRadius * 2 * Math.PI;
	const arc = circumference * (180 / 360);
	const dashArray = `${arc} ${circumference}`;
	const transform = `rotate(180, ${radius}, ${radius})`;
	const percentNormalized = Math.min(Math.max(percent, 0), 100);
	const offset = arc - (percentNormalized / 100) * arc;

	return (
		<svg height={radius} width={radius * 2}>
			<circle
				class='gauge_base'
				cx={radius}
				cy={radius}
				fill='transparent'
				r={innerRadius}
				stroke='rgb(203 213 225)'
				strokeWidth={strokeWidth}
				strokeDasharray={dashArray}
				transform={transform}
			/>
			<circle
				class='gauge_percent'
				cx={radius}
				cy={radius}
				fill='transparent'
				r={innerRadius}
				stroke='#00BC9C'
				strokeDasharray={dashArray}
				strokeDashoffset={offset}
				strokeWidth={strokeWidth}
				transform={transform}
			/>
		</svg>
	);
}
