import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalTemplate from "../../../components/ui/ModalTemplate";
import ToolTipEmpty from "../../../components/ui/ToolTipEmpty";
import { createToDos } from "../../../redux/actions/toDoActions";

function RecommendationsModal({
	setShowRecommendations,
	recommendationsToShow,
	activeFilter,
	setActiveFilter,
}) {
	const [loading, setLoading] = useState(false);
	const onClose = () => {
		setShowRecommendations(false);
	};
	const tasksState = useSelector((state) => state.tasks);

	const dispatch = useDispatch();

	const addToDoHandler = (task) => {
		setLoading(true);
		const newToDo = {
			recommendationId: task.id,
			pageId: recommendationsToShow.id,
			description: task.description,
			page: recommendationsToShow.path,
		};
		dispatch(createToDos(newToDo));
	};

	useEffect(() => {
		console.log("Use Effect", tasksState.actionStatus);
		if (tasksState.actionStatus === "COMPLETE") {
			console.log("I should call");
			setShowRecommendations(false);
		}
	}, [tasksState.actionStatus, setShowRecommendations]);

	return (
		<ModalTemplate title={`Recommendations for ${recommendationsToShow.path}`} onClose={onClose}>
			<div className='flex flex-col border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none'>
				<div className='flex flex-col px-6 py-4'>
					{recommendationsToShow.recommendations.length > 0 ? (
						recommendationsToShow.recommendations.map((row, index) => {
							const num = index + 1;
							return (
								<div className='flex justify-between items-center mb-4' key={row.id}>
									<p className='p-1'>
										{num}. {row.description}
									</p>
									<ToolTipEmpty message='Add to to-do list'>
										<button
											className=' bg-green-500 rounded-full h-8 w-8 text-white text-xl shadow-lg'
											onClick={() => addToDoHandler(row)}
											disabled={loading}
										>
											+
										</button>
									</ToolTipEmpty>
								</div>
							);
						})
					) : (
						<p className='text-center text-xl'>No new recommendations</p>
					)}
				</div>
			</div>
		</ModalTemplate>
	);
}

export default RecommendationsModal;
