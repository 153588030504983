import React from "react";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import SubscriptionCards from "./components/SubscriptionCards";

function SubscribeScreen() {
  return (
    <div className='flex flex-col w-full px-8 py-4 items-center'>
      <QualiscoreLogo classNameDiv='w-4/12' />
      <SubscriptionCards />
    </div>
  );
}

export default SubscribeScreen;
