import React from "react";
import Button from "./ui/Button";

function SubscriptionCard({
	packageName,
	includedUrls,
	price,
	label,
	onClick,
	hideButton,
	disableButton,
	hideTrial,
}) {
	return (
		<div className='flex flex-col w-10/12 mb-4 md:mb-0 md:w-1/5 text-center border h-64'>
			{!hideTrial && <div className='bg-qualiscoreGreen text-white'>Free 30 day trial</div>}
			<div className='flex flex-col grow w-full text-center p-2 justify-around'>
				<h3 className='font-bold text-xl'>{packageName}</h3>
				<p className=''>
					<span className='font-semibold'>${(price / 100).toFixed(2)}</span> / User / Month
				</p>
				<hr className='border-black' />
				<p>{includedUrls} URLs</p>
				{!hideButton && <Button label={label} onClick={onClick} disabled={disableButton} />}
			</div>
		</div>
	);
}

export default SubscriptionCard;
