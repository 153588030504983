import * as t from "../constants/analyticsConstants";

export const analyticsReducer = (
  state = {
    data: null,
    loaded: false,
    actionStatus: null,
  },
  action
) => {
  switch (action.type) {
    case t.GET_META_DATA:
      return {
        data: action.payload,
        loaded: true,
      };
    case t.UPDATE_URL:
      const existingUrls = state.data.urls.filter(
        (url) => url.id !== action.payload.id
      );
      return {
        ...state,
        data: {
          urls: [...existingUrls, action.payload],
          totalUrls: state.data.totalUrls,
          qualiScore: state.data.qualiScore,
          prevQualiscore: state.data.prevQualiscore,
          scoresArray: state.data.scoresArray,
          pagesScoreIncreased: state.data.pagesScoreIncreased,
          pagesScoreDecreased: state.data.pagesScoreDecreased,
        },
      };
    case t.ACTION_LOADING_ANALYTICS:
      return {
        ...state,
        actionStatus: "LOADING",
      };
    case t.ACTION_COMPLETE_ANALYTICS:
      return {
        ...state,
        actionStatus: "COMPLETE",
      };
    case t.ACTION_FAILED_ANALYTICS:
      return {
        ...state,
        actionStatus: "FAILED",
      };
    case t.ACTION_RESET_ANALYTICS:
      return {
        ...state,
        actionStatus: null,
      };
    default:
      return state;
  }
};
