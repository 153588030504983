import React, { useEffect, useMemo, useState } from "react";
import RecommendationsModal from "./RecommendationsModal";
import Pagination from "../../../components/Pagination";

export default function PageScoreTable({
	urls,
	filteredUrls,
	setFilteredUrls,
	activeFilter,
	setActiveFilter,
	setPathFilter,
}) {
	const [currentPage, setCurrentPage] = useState(1);
	const [showRecommendations, setShowRecommendations] = useState(false);
	const [recommendationsToShow, setRecommendationsToShow] = useState(null);
	const [pathSearch, setPathSearch] = useState("");
	const [urlsToDisplay, setUrlsToDisplay] = useState([]);
	const [pageSize, setPageSize] = useState(10);

	const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		return urlsToDisplay.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, pageSize, urlsToDisplay]);

	const viewRecommendationsHandler = (path) => {
		setRecommendationsToShow(path);
		setShowRecommendations(true);
	};

	useEffect(() => {
		setUrlsToDisplay(filteredUrls);
	}, [filteredUrls]);

	useEffect(() => {
		if (pathSearch !== "") {
			const newFilteredUrls = urls.filter((url) =>
				url.path.toLowerCase().includes(pathSearch.toLowerCase())
			);
			setFilteredUrls(newFilteredUrls);
			setActiveFilter("path");
			setPathFilter(pathSearch);
			setCurrentPage(1);
		}
	}, [pathSearch, urls, setActiveFilter, setFilteredUrls, setPathFilter]);

	return (
		<div className='flex flex-col w-full bg-white p-8 overflow-x-scroll no-scrollbar mb-8'>
			<h3 className='text-3xl text-black'>Page Scores</h3>
			<div className='flex flex-col p-4 shadow-xl rounded-lg overflow-clip mt-8'>
				<div className='flex p-2'>
					<div className='w-[300px] relative p-2 border border-slate-300 rounded-md'>
						<p className='bg-white px-2 absolute left-2 -top-2 text-xs text-slate-500'>Search</p>
						<input
							className='w-full outline-none'
							value={pathSearch}
							onChange={(e) => setPathSearch(e.target.value)}
						/>
					</div>
				</div>
				{urlsToDisplay && (
					<table className='table-auto w-full mx-auto mt-4 no-scrollbar'>
						<thead>
							<tr className='border-b'>
								<th className='text-sm text-left py-4'>Page</th>
								<th className='text-sm px-8'>Recommendations</th>
								<th className='text-sm'>Page Score</th>
								<th className='text-sm px-4'>Score Delta (Previous 30 days)</th>
							</tr>
						</thead>
						<tbody>
							{urls &&
								currentTableData.map((row, index) => (
									<tr key={row.id} className='border-b'>
										<td className='text-sm max-w-[450px] overflow-x-scroll no-scrollbar whitespace-nowrap py-3'>
											{row.path}
										</td>
										<td className='text-center py-3'>
											{row.score === 9 ? (
												<p>Optimized</p>
											) : row.recommendations ? (
												<button
													onClick={() => viewRecommendationsHandler(row)}
													className='text-blue-400 '
												>
													View
												</button>
											) : (
												"No Recommendations yet"
											)}
										</td>
										<td className='text-center py-3'>
											<p className='bg-slate-100 w-8 mx-auto rounded-full p-2'>{row.score}</p>
										</td>
										<td className='text-center py-3'>{row.score - row.prevScore}</td>
									</tr>
								))}
						</tbody>
					</table>
				)}
				{currentTableData?.length === 0 && (
					<p className='w-full text-center p-4'>No pages to display</p>
				)}
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					totalCount={filteredUrls.length}
					currentPage={currentPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
				/>
			</div>
			{showRecommendations && (
				<RecommendationsModal
					setShowRecommendations={setShowRecommendations}
					recommendationsToShow={recommendationsToShow}
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
				/>
			)}
		</div>
	);
}
