import React, { useState } from "react";
import ModalTemplate from "../../../components/ui/ModalTemplate";
import TextField from "../../../components/ui/TextField";
import CheckField from "../../../components/ui/CheckField";
import Button from "../../../components/ui/Button";
import { toast } from "react-toastify";
import { firebaseAuth } from "../../../firebase";
import axios from "axios";

export default function AddUserModal({
	setShowModal,
	companyUsers,
	setCompanyUsers,
}) {
	const [loading, setLoading] = useState(false);
	const [newUser, setNewUser] = useState({
		fullName: "",
		email: "",
		companyAdmin: false,
	});

	const onInviteHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (newUser.fullName === "") {
			toast.error("Please provide full name");
			setLoading(false);
			return;
		}
		if (newUser.email === "") {
			toast.error("Please provide email");
			setLoading(false);
			return;
		}
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.post(
					`${process.env.REACT_APP_BACKEND}/company/users`,
					{
						newUser,
					},
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);
				setCompanyUsers([...companyUsers, data]);
				toast.success("User invited");
				setShowModal(false);
			} catch (error) {
				console.log(error);
				toast.error("Error inviting user");
				setLoading(false);
			}
		});
	};

	const onCloseHandler = () => {
		setShowModal(false);
	};

	return (
		<ModalTemplate onClose={() => onCloseHandler()} title='Invite New User'>
			<form className='flex flex-col items-center px-2 py-4'>
				<TextField
					label='Full Name'
					type='text'
					placeholder='Enter name'
					value={newUser.fullName}
					onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })}
					name='inviteNameField'
				/>
				<TextField
					label='Email Address'
					type='email'
					placeholder='Enter email address'
					value={newUser.email}
					onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
					name='inviteEmailField'
				/>
				<CheckField
					label='Set as admin'
					hideLabel={false}
					value={newUser.companyAdmin}
					onChange={(e) =>
						setNewUser({ ...newUser, companyAdmin: e.target.checked })
					}
					name='checkCompanyAdmin'
				/>
				<Button
					label='Invite'
					onClick={(e) => onInviteHandler(e)}
					disabled={loading}
				/>
			</form>
		</ModalTemplate>
	);
}
