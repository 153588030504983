import axios from "axios";
import React, { useEffect, useState } from "react";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Layout from "../../components/layout/Layout";
import { firebaseAuth } from "../../firebase";

function LinkGoogle() {
	const [consentUrl, setConsentUrl] = useState(null);
	const [loading, setLoading] = useState(false);

	const linkNowClickHandler = () => {
		setLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/consent`, {
					headers: {
						Authorization: "Bearer " + idToken,
					},
				});
				setConsentUrl(data);
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		if (consentUrl !== null) {
			window.location.href = consentUrl;
		}
	}, [consentUrl]);
	return (
		<Layout>
			<div className='flex flex-col grow justify-center items-center'>
				<QualiscoreLogo classNameDiv='w-4/12' />
				<h3 className='text-xl mb-8'>Link your google analytics account</h3>
				<button className='w-2/12' onClick={() => linkNowClickHandler()}>
					<img
						src='/assets/images/GoogleSignInButton.png'
						className='w-full'
						alt='Qualiscore Logo'
					/>
				</button>
				{loading && <LoadingSpinner />}
			</div>
		</Layout>
	);
}

export default LinkGoogle;
