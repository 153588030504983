import React, { useEffect, useState } from "react";
import Button from "../../../components/ui/Button";
import { firebaseAuth } from "../../../firebase";
import axios from "axios";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import UserTable from "./UserTable";
import AddUserModal from "./AddUserModal";

export default function ManageUsers() {
	const [loading, setLoading] = useState(false);
	const [companyUsers, setCompanyUsers] = useState([]);
	const [showAddUserModal, setShowAddUserModal] = useState(false);

	useEffect(() => {
		if (companyUsers.length === 0) {
			setLoading(true);
			firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
				try {
					const { data } = await axios.get(
						`${process.env.REACT_APP_BACKEND}/company/users`,
						{
							headers: {
								Authorization: "Bearer " + idToken,
							},
						}
					);
					setCompanyUsers(data);
					setLoading(false);
				} catch (error) {
					console.log(error);
				}
			});
		}
	}, [companyUsers.length]);

	return (
		<div className='flex flex-col grow mx-4 px-8 py-4 bg-white shadow-lg'>
			<div className='flex mb-8'>
				<Button label='Invite User' onClick={() => setShowAddUserModal(true)} />
			</div>
			{loading && (
				<div className='flex flex-col justify-center items-center'>
					<LoadingSpinner />
					<p className='mt-4'>Getting users</p>
				</div>
			)}
			{!loading && companyUsers.length === 0 && (
				<div className='flex flex-col justify-center items-center'>
					<p>No users found</p>
				</div>
			)}
			{!loading && companyUsers.length > 0 && (
				<UserTable users={companyUsers} setCompanyUsers={setCompanyUsers} />
			)}
			{showAddUserModal && (
				<AddUserModal
					setShowModal={setShowAddUserModal}
					companyUsers={companyUsers}
					setCompanyUsers={setCompanyUsers}
				/>
			)}
		</div>
	);
}
