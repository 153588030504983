import React from "react";
import BurgerMenu from "../ui/BurgerMenu";
import QualiscoreLogo from "../QualiscoreLogo";

export default function Header({ showSideNav, setShowSideNav }) {
	return (
		<div className='flex justify-between bg-white h-20 p-4 lg:h-8'>
			<QualiscoreLogo classNameDiv='w-3/12 md:w-2/12 lg:hidden' />
			<button onClick={() => setShowSideNav(!showSideNav)}>
				<BurgerMenu />
			</button>
		</div>
	);
}
