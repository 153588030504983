import axios from "axios";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Layout from "../../components/layout/Layout";
import Button from "../../components/ui/Button";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";
import SettingsNav from "./components/SettingsNav";

function SecuritySettings() {
	const [loading, setLoading] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [resetIgnoredPath, setResetIgnoredPath] = useState(false);
	const [newPassword, setNewPassword] = useState({
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	const user = firebaseAuth.currentUser;

	const dispatch = useDispatch();

	const changePasswordHandler = (e) => {
		e.preventDefault();
		if (newPassword.newPassword !== newPassword.confirmNewPassword) {
			toast.error("New passwords do not match");
			return;
		}
		const credential = EmailAuthProvider.credential(user.email, newPassword.currentPassword);
		reauthenticateWithCredential(user, credential)
			.then(() => {
				updatePassword(user, newPassword.newPassword)
					.then(() => {
						// Password update successful
						toast.success("Password Updated");
						setChangePassword(false);
					})
					.catch((error) => {
						// Error occured with update password
						toast.error("Error updating password");
					});
			})
			.catch((error) => {
				// Error occured with reauthenticating user
				toast.error("Error updating credential");
				console.log(error);
			});
	};

	const resetIgnoreHandler = () => {
		setLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/url/ignore`, {
					headers: {
						Authorization: "Bearer " + idToken,
					},
				});
				dispatch({
					type: "GET_META_DATA",
					payload: data,
				});
				toast.success("Ignored paths reset");
				setLoading(false);
			} catch (error) {
				console.log(error);
				toast.error("Error resetting ignored paths");
				setLoading(false);
			}
		});
	};

	const optionsSelect = (option) => {
		switch (option) {
			case "change-password":
				setChangePassword(!changePassword);
				break;

			case "reset-ignored-paths":
				setChangePassword(false);
				setResetIgnoredPath(!resetIgnoredPath);
				break;

			default:
				setChangePassword(false);
				setResetIgnoredPath(false);
				break;
		}
	};

	return (
		<Layout>
			<SettingsNav />
			<div className='flex px-6 py-6'>
				<div className='flex flex-col w-fit ml-2 px-8 py-4 bg-white shadow-lg space-y-4'>
					<Button
						className=''
						label='Change Password'
						selected={changePassword}
						onClick={() => optionsSelect("change-password")}
					/>
					<Button
						label='Reset Ignored Paths'
						selected={resetIgnoredPath}
						onClick={() => optionsSelect("reset-ignored-paths")}
					/>
				</div>
				{/* Below displayed when change password active */}
				{changePassword && (
					<div className='px-8 py-2 bg-white shadow-lg ml-2 mt-2'>
						<form className='flex flex-col w-full border px-2 py-2 space-y-2 justify-center'>
							<input
								className='hidden'
								type='email'
								autoComplete='username'
								value={user.email}
								readOnly
							/>
							<TextField
								label='Current Password'
								type='password'
								autoComplete='current-password'
								placeholder='Enter current password'
								value={newPassword.currentPassword}
								onChange={(e) =>
									setNewPassword({
										...newPassword,
										currentPassword: e.target.value,
									})
								}
							/>
							<TextField
								label='New Password'
								type='password'
								autoComplete='new-password'
								placeholder='Enter new password'
								value={newPassword.newPassword}
								onChange={(e) =>
									setNewPassword({
										...newPassword,
										newPassword: e.target.value,
									})
								}
							/>
							<TextField
								label='Confirm New Password'
								type='password'
								autoComplete='new-password'
								placeholder='Confirm new password'
								value={updatePassword.confirmNewPassword}
								onChange={(e) =>
									setNewPassword({
										...newPassword,
										confirmNewPassword: e.target.value,
									})
								}
							/>
							<Button label='Update' onClick={(e) => changePasswordHandler(e)} />
						</form>
					</div>
				)}
				{/* Below displayed when reset ignored paths active */}
				{resetIgnoredPath && (
					<div className='flex flex-col px-8 py-2 bg-white shadow-lg ml-4'>
						<p className='w-7/12 text-center mx-auto'>
							Clicking below will reset all your ignored paths. Please only do so if you understand
							you may need to re-ignore paths.
						</p>
						{!loading && (
							<button
								className='bg-qualiscoreGreen text-white px-6 py-2 rounded-lg my-4 w-6/12 mx-auto'
								onClick={() => resetIgnoreHandler()}
							>
								Reset Ignore
							</button>
						)}
						{loading && (
							<div className='mx-auto my-4'>
								<LoadingSpinner />
							</div>
						)}
					</div>
				)}
			</div>
		</Layout>
	);
}

export default SecuritySettings;
