import React from "react";
import { NavLink } from "react-router-dom";

function ActiveNavButton({ to, label }) {
	return (
		<NavLink
			className={({ isActive }) =>
				isActive
					? "p-4 border-t-2 border-b-2 border-white hover:border-qualiscoreGreen text-qualiscoreGreen"
					: "p-4 border-t-2 border-b-2 border-white hover:border-qualiscoreGreen"
			}
			to={to}
		>
			{label}
		</NavLink>
	);
}

export default ActiveNavButton;
