import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import { getToDos } from "../../redux/actions/toDoActions";
import ToDoTable from "./components/ToDoTable";

function ToDoScreen() {
	const tasks = useSelector((state) => state.tasks);
	const toDos = useSelector((state) => state.tasks.toDo);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!tasks.loaded) {
			dispatch(getToDos());
		}
	}, [tasks.loaded, dispatch]);

	return (
		<Layout loading={!tasks.loaded}>
			<div className='flex flex-col px-6 py-6'>
				<div className='flex justify-around mb-8'>
					<div className='flex flex-col p-8 bg-white text-center rounded-lg shadow-lg'>
						<p className='text-3xl'>Outstanding Tasks</p>
						<p className='text-4xl'>{toDos?.filter((todo) => !todo.complete).length} / 15</p>
					</div>
					<div className='flex flex-col p-8 bg-white text-center rounded-lg shadow-lg'>
						<p className='text-3xl'>Completed Tasks</p>
						<p className='text-4xl'>{toDos?.filter((todo) => todo.complete).length}</p>
					</div>
				</div>
				<ToDoTable />
			</div>
		</Layout>
	);
}

export default ToDoScreen;
