import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ToolTip from "../../../components/ui/ToolTipEmpty";
import {
  deleteToDo,
  getToDos,
  markToDoComplete,
} from "../../../redux/actions/toDoActions";

function ToDoTable() {
  const tasks = useSelector((state) => state.tasks);
  const toDos = useSelector((state) => state.tasks.toDo);
  const [toDosToMap, setToDosToMap] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tasks.loaded) {
      dispatch(getToDos());
    }
  }, [tasks, dispatch]);

  useEffect(() => {
    if (tasks.loaded) {
      const outstandingToDos = toDos?.filter((toDo) => !toDo.complete);
      setToDosToMap(outstandingToDos);
    }
  }, [tasks, toDos]);

  const markTaskCompleteHandler = (todo) => {
    dispatch(markToDoComplete(todo));
  };

  const deleteTaskHandler = (todo) => {
    dispatch(deleteToDo(todo));
  };

  return (
    <div>
      <h3 className='text-center font-semibold text-xl mb-4'>To Do List</h3>
      {toDos?.length > 0 && (
        <div className='flex flex-wrap justify-around'>
          {toDosToMap.map((row, index) => (
            <div
              className='flex flex-col justify-between w-3/12 ml-4 mb-4 bg-white rounded-lg shadow-lg'
              key={row.id}
            >
              <div className='flex justify-between w-full bg-qualiscoreGreen p-2 rounded-t-lg'>
                {index + 1}
                <p>{row.page}</p>
              </div>
              <div className='flex flex-col px-4 py-6'>
                <p>{row.description}</p>
              </div>
              <div className='flex justify-end p-2  rounded-b-lg'>
                <ToolTip message='Delete Task'>
                  <button
                    className='bg-red-200 hover:bg-red-600 hover:text-white hover:shadow-lg w-8 h-8 rounded-full'
                    onClick={() => deleteTaskHandler(row)}
                  >
                    &#10005;
                  </button>
                </ToolTip>
                <ToolTip message='Mark Task Complete'>
                  <button
                    className='bg-qualiscoreGreen hover:bg-qualiscoreDarkBlue hover:text-white hover:shadow-lg w-8 h-8 rounded-full'
                    onClick={() => markTaskCompleteHandler(row)}
                  >
                    &#10003;
                  </button>
                </ToolTip>
              </div>
            </div>
          ))}
        </div>
      )}
      {toDosToMap?.length === 0 && (
        <div className='flex flex-col items-center bg-white w-3/12 p-4 rounded-lg shadow-lg mx-auto text-center'>
          <p className='text-2xl mb-4'>You are all up to date!</p>
          <p>
            Go to your{" "}
            <span className='text-blue-500 underline'>
              <Link to='/'> dashboard</Link>
            </span>{" "}
            to view more recommendations
          </p>
        </div>
      )}
    </div>
  );
}

export default ToDoTable;
