import React from "react";
import ActiveNavLink from "../../../components/ui/ActiveNavLink";

function AdminNav() {
	return (
		<div className='flex bg-white px-8 space-x-2 text-white text-center shadow-lg'>
			<ActiveNavLink to='/admin/users' label='Users' />
			<ActiveNavLink to='/admin/recommendations' label='Recommendations' />
		</div>
	);
}

export default AdminNav;
