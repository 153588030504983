import React from "react";
import QualiscoreLogo from "../QualiscoreLogo";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../redux/actions/authActions";
import NavigationMenu from "../NavigationMenu";

export default function Sidebar() {
	const dispatch = useDispatch();

	const signOutHandler = () => {
		dispatch(signOutUser());
	};

	return (
		<div className='hidden lg:flex flex-col justify-between bg-white w-2/12'>
			<QualiscoreLogo classNameDiv='flex w-11/12' />
			<NavigationMenu />
			<button
				className='w-full bg-qualiscoreDarkBlue text-white h-[54px]'
				onClick={() => signOutHandler()}
			>
				Log Out
			</button>
		</div>
	);
}
