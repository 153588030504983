import { configureStore } from "@reduxjs/toolkit";
import { analyticsReducer } from "./reducers/analyticsReducer";
import { authReducer } from "./reducers/authReducer";
import { toDoReducer } from "./reducers/toDoReducer";
import LogRocket from "logrocket";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		analytics: analyticsReducer,
		tasks: toDoReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(LogRocket.reduxMiddleware()),
});
