import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import { formattedDate, getDaysBetweenDates } from "../../utils/formattedDate";
import axios from "axios";
import { firebaseAuth } from "../../firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import SubscriptionCard from "../../components/SubscriptionCard";
import SettingsNav from "./components/SettingsNav";

function SubscriptionSettings() {
	const auth = useSelector((state) => state.auth);
	const user = auth.user;
	const subscription = user.companyDetails.subscriptionDetails;
	const [loading, setLoading] = useState(false);
	const [upgrade, setUpgrade] = useState(false);
	const [cancel, setCancel] = useState(false);
	const [subscriptionCards, setSubscriptionCards] = useState([]);

	const navigate = useNavigate();

	const upgradeHandler = (selectedSubscription) => {
		setLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.put(
					`${process.env.REACT_APP_BACKEND}/subscriptions`,
					{
						selectedSubscription,
					},
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);
				if (data === "success") {
					toast.success("Subscription Upgraded");
				}
				const interval = setInterval(() => {
					navigate(0);
				}, 2500);
				return () => clearInterval(interval);
			} catch (error) {
				console.log(error);
				toast.error("Error Upgrading Subscription");
				setLoading(false);
			}
		});
	};

	const cancelHandler = () => {
		setLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/subscriptions/cancel`, {
					headers: {
						Authorization: "Bearer " + idToken,
					},
				});
				if (data === "success") {
					toast.success("Subscription Cancelled");
				}
				const interval = setInterval(() => {
					navigate(0);
				}, 2500);
				return () => clearInterval(interval);
			} catch (error) {
				console.log(error);
				toast.error("Error Cancelling Subscription");
				setLoading(false);
			}
		});
	};

	const resubscribeHandler = () => {
		setLoading(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.get(
					`${process.env.REACT_APP_BACKEND}/subscriptions/reactivate`,
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);
				if (data === "success") {
					toast.success("Subscription Re-activated");
				}
				const interval = setInterval(() => {
					navigate(0);
				}, 2500);
				return () => clearInterval(interval);
			} catch (error) {
				console.log(error);
				toast.error("Error Re-activating Subscription");
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		if (subscriptionCards.length === 0) {
			firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
				try {
					const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/packages`, {
						headers: {
							Authorization: "Bearer " + idToken,
						},
					});
					const returnData = data.filter((item) => item.price > subscription.packageDetails.price);

					returnData.sort((a, b) => {
						if (a.price > b.price) return 1;
						if (a.price < b.price) return -1;
						return 0;
					});

					setSubscriptionCards(returnData);
				} catch (error) {
					console.log(error);
				}
			});
		}
	});

	return (
		<Layout>
			<SettingsNav />
			<div className='flex grow px-6 py-6 justify-between'>
				<div className='flex flex-col w-9/12 md:w-7/12 lg:w-5/12 xl:w-4/12 ml-2 px-8 py-4 bg-white shadow-lg space-y-4'>
					{subscription.trialPeriod && (
						<div className='bg-qualiscoreGreen text-white text-center p-2'>
							<p>
								Free trial - {getDaysBetweenDates(new Date(), subscription.nextPayment).toFixed(0)}{" "}
								days remaining
							</p>
						</div>
					)}
					<div className='flex justify-between'>
						<p>Current Package:</p>
						<p>{subscription.packageDetails.packageName}</p>
					</div>
					<div className='flex justify-between'>
						<p>Included Pages:</p>
						<p>
							{subscription.packageDetails.includedUrls === 9999999
								? "Unlimited"
								: subscription.packageDetails.includedUrls}
						</p>
					</div>
					<div className='flex justify-between'>
						<p>Monthly Price per User:</p>
						<p>${(subscription.packageDetails.price / 100)?.toFixed(2)}</p>
					</div>
					<div className='flex justify-between'>
						<p>Next Payment Due:</p>
						<p>{subscription.cancelled ? "Cancelled" : formattedDate(subscription.nextPayment)}</p>
					</div>
					<div className='flex justify-between'>
						<p>Total Users:</p>
						<p>{subscription.totalUsers}</p>
					</div>
					<div className='flex justify-between'>
						<p>Total Monthly Price:</p>
						<p>
							${((subscription.packageDetails.price / 100) * subscription.totalUsers)?.toFixed(2)}
						</p>
					</div>
					{!subscription.cancelled && (
						<div className='flex flex-col space-y-2 md:space-y-0 md:flex-row md:justify-around'>
							{subscription.packageDetails.includedUrls !== 9999999 && (
								<button
									className='bg-qualiscoreGreen text-white px-6 py-2 rounded-lg'
									onClick={() => {
										setUpgrade(!upgrade);
										setCancel(false);
									}}
								>
									Upgrade
								</button>
							)}
							<button
								className='bg-red-300 text-white px-6 py-2 rounded-lg'
								onClick={() => {
									setCancel(!cancel);
									setUpgrade(false);
								}}
							>
								Cancel
							</button>
						</div>
					)}
				</div>
				{upgrade && (
					<div className='flex flex-col w-8/12 mx-4 px-8 py-4 bg-white shadow-lg text-center'>
						<h3 className='text-lg font-bold'>Select new package</h3>
						<p className='text-center mb-2'>
							You will be charged a prorated amount today and your new subscription will commence
							instantly.
						</p>
						<div className='flex grow justify-evenly'>
							{subscriptionCards.map((card) => (
								<SubscriptionCard
									key={card.id}
									packageName={card.packageName}
									includedUrls={card.includedUrls === 9999999 ? "Unlimited" : card.includedUrls}
									price={card.price}
									label='Upgrade'
									onClick={() => upgradeHandler(card)}
									hideButton={loading}
									disableButton={loading}
									hideTrial={true}
								/>
							))}
						</div>
						{loading && (
							<div className='mx-auto my-4'>
								<LoadingSpinner />
							</div>
						)}
					</div>
				)}
				{cancel && (
					<div className='flex flex-col w-8/12 mx-4 px-8 py-4 bg-white shadow-lg text-center'>
						<h3 className='text-lg font-bold'>Cancel Qualiscore Subscription</h3>
						<p className='text-center mb-2 w-8/12 mx-auto'>
							If you proceed to cancel at the end of your current billing period your data will be
							removed and you will lose access to Qualiscore.
						</p>
						<div className='flex flex-col w-full'>
							<p className='text-lg font-semibold'>Confirm cancellation</p>
							{!loading && (
								<button
									className='bg-red-400 w-6/12 mx-auto text-white px-6 py-2 rounded-lg mt-4'
									onClick={() => cancelHandler()}
								>
									Cancel
								</button>
							)}
							{loading && (
								<div className='mx-auto my-4'>
									<LoadingSpinner />
								</div>
							)}
						</div>
					</div>
				)}
				{subscription.cancelled && (
					<div className='flex flex-col w-8/12 mx-4 px-8 py-4 bg-white shadow-lg text-center'>
						<p className='text-red-500 text-lg'>
							<span className='font-bold'>Warning! </span>You have cancelled your subscription and
							you will lose access on {formattedDate(subscription.endSubscription)}
						</p>
						<div className='flex flex-col mt-8 w-6/12 mx-auto'>
							<p>
								You can reactivate your subscription anytime before{" "}
								{formattedDate(subscription.endSubscription)} by clicking re-subscribe below.
							</p>
							<p className='mt-4'>
								We will collect the next payment at the end of the current billing period.
							</p>
							{!loading && (
								<button
									className='bg-qualiscoreGreen text-white px-6 py-2 rounded-lg my-4'
									onClick={() => resubscribeHandler()}
								>
									Re-subscribe
								</button>
							)}
							{loading && (
								<div className='mx-auto my-4'>
									<LoadingSpinner />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
}

export default SubscriptionSettings;
