export const formattedDate = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = String(today.getFullYear());

  today = mm + "/" + dd + "/" + yyyy;
  return today;
};

export const nextMonthFormattedDate = (date) => {
  var nextDate = new Date(date);
  var dd = String(nextDate.getDate()).padStart(2, "0");
  var mm = String(nextDate.getMonth() + 2).padStart(2, "0");
  var yyyy = String(nextDate.getFullYear());

  nextDate = mm + "/" + dd + "/" + yyyy;
  return nextDate;
};

export const getDaysBetweenDates = (dateOne, dateTwo) => {
  var startDate = new Date(dateOne);
  var endDate = new Date(dateTwo);

  var differenceInTime = endDate.getTime() - startDate.getTime();

  var differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
};
