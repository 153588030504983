import React, { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import LoadingSpinner from "../ui/LoadingSpinner";
import SideNav from "./components/SideNav";

export default function Layout({ children, loading }) {
	const [showSideNav, setShowSideNav] = useState(false);

	return (
		<div className='flex min-h-screen max-h-screen bg-slate-200'>
			<Sidebar />
			<div className='flex flex-col grow overflow-x-clip'>
				<Header showSideNav={showSideNav} setShowSideNav={setShowSideNav} />
				{loading && (
					<div className='flex flex-col grow justify-center items-center'>
						<LoadingSpinner />
						<p className='mt-4'>Gathering Data...</p>
					</div>
				)}
				{!loading && (
					<div className='flex flex-col overflow-y-scroll overflow-x-scroll'>
						{showSideNav && <SideNav setShowSideNav={setShowSideNav} />}
						{children}
					</div>
				)}
			</div>
		</div>
	);
}
