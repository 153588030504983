import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SubscriptionCard from "../../../components/SubscriptionCard";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../../firebase";
import CheckoutForm from "./CheckoutForm";
function SubscriptionCards() {
	const [loading, setLoading] = useState(false);
	const [subscriptionCards, setSubscriptionCards] = useState([]);
	const [subscription, setSubscription] = useState(null);
	const [stripePayment, setStripePayment] = useState(null);
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

	const options = {
		// passing the client secret obtained from the server
		clientSecret: stripePayment?.clientSecret,
	};

	const subscribeHandler = (selectedSubscription) => {
		setLoading(true);
		setSubscription(selectedSubscription);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.post(
					`${process.env.REACT_APP_BACKEND}/subscriptions`,
					{
						selectedSubscription,
					},
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);
				setStripePayment(data);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		});
	};

	subscriptionCards.sort((a, b) => {
		if (a.price > b.price) return 1;
		if (a.price < b.price) return -1;
		return 0;
	});

	console.log(stripePayment);

	useEffect(() => {
		if (subscriptionCards.length === 0) {
			firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
				try {
					const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/packages`, {
						headers: {
							Authorization: "Bearer " + idToken,
						},
					});
					console.log(data);
					setSubscriptionCards(data);
				} catch (error) {
					console.log(error);
				}
			});
		}
	});

	return loading ? (
		<div>
			<LoadingSpinner />
		</div>
	) : subscriptionCards.length === 0 ? (
		<div className='flex flex-col mt-10 items-center'>
			<LoadingSpinner />
			<p className='mt-4 text-lg'>Loading Subscriptions</p>
		</div>
	) : stripePayment === null ? (
		<div className='flex flex-col mt-10 w-10/12 justify-between px-4'>
			<h2 className='text-center text-2xl font-semibold'>Subscribe to Qualiscore</h2>
			<div className='flex flex-col md:flex-row w-full items-center md:justify-between px-4 mt-8'>
				{subscriptionCards.map((card) => (
					<SubscriptionCard
						key={card.id}
						packageName={card.packageName}
						includedUrls={card.includedUrls}
						price={card.price}
						label='Subscribe'
						onClick={() => subscribeHandler(card)}
					/>
				))}
			</div>
		</div>
	) : (
		<div>
			<Elements stripe={stripePromise} options={options}>
				<CheckoutForm subscription={subscription} />
			</Elements>
		</div>
	);
}

export default SubscriptionCards;
