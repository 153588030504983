import React, { useState } from "react";
import ModalTemplate from "../../../components/ui/ModalTemplate";
import LabelTooltip from "../../../components/ui/LabelTooltip";
import Button from "../../../components/ui/Button";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../../firebase";
import axios from "axios";
import { toast } from "react-toastify";

function AddRecommendationModal({ setAddRecommendationModal }) {
  const [loading, setLoading] = useState(false);
  const [newRecommendation, setNewRecommendation] = useState({
    score: 1,
    description: "",
  });

  const saveRecommendationHandler = () => {
    setLoading(true);
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND}/admin/recommendation`,
          {
            newRecommendation,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data.id) {
          toast.success("Recommendation Added");
          setAddRecommendationModal(false);
        } else {
          toast.error("Error saving recommendation");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const onCloseHandler = () => {
    setAddRecommendationModal(false);
  };

  return (
    <ModalTemplate title='Create Recommendation' onClose={onCloseHandler}>
      <div className='px-6 py-4'>
        <div className='flex justify-between items-center'>
          <LabelTooltip
            label='Score'
            message='Select score that recommendation applies to'
          />
          <select
            className='w-4/12 text-center border rounded-lg p-2'
            value={newRecommendation.score}
            onChange={(e) =>
              setNewRecommendation({
                ...newRecommendation,
                score: parseInt(e.target.value),
              })
            }
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
          </select>
        </div>
        <div className='flex flex-col mt-4'>
          <LabelTooltip
            label='Recommendation'
            message='Enter recommendation to be displayed'
          />
          <textarea
            className='border rounded-lg mt-2 p-2'
            rows={5}
            value={newRecommendation.description}
            onChange={(e) =>
              setNewRecommendation({
                ...newRecommendation,
                description: e.target.value,
              })
            }
          />
        </div>
        <div className='flex mt-4 w-full justify-end'>
          {loading ? (
            <div className='flex w-full justify-center'>
              <LoadingSpinner />
            </div>
          ) : (
            <Button label='Save' onClick={() => saveRecommendationHandler()} />
          )}
        </div>
      </div>
    </ModalTemplate>
  );
}

export default AddRecommendationModal;
