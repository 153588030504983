import React from "react";

function QualiscoreLogo({ classNameDiv }) {
  return (
    <div className={classNameDiv}>
      <img
        src='/assets/images/QualiscoreLogo.png'
        className='w-full'
        alt='Qualiscore Logo'
      />
    </div>
  );
}

export default QualiscoreLogo;
