import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../../firebase";
import EditRecommendationModal from "./EditRecommendationModal";

function RecommendationsTable({ addRecommendationModal }) {
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [disableButtons, setDisableButtons] = useState(false);
  const [recommendations, setRecommendations] = useState(null);
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [editRecommendationModal, setEditRecommendationModal] = useState(false);
  const [editRecommendation, setEditRecommendation] = useState({
    id: "",
    score: 1,
    description: "",
  });

  const scoreFilter = (score, filter) => {
    const newFilteredRecommendations = recommendations.filter(
      (item) => item.score === score
    );
    setFilteredRecommendations(newFilteredRecommendations);
    setActiveFilter(filter);
  };

  const clearFilters = () => {
    setFilteredRecommendations(recommendations);
    setActiveFilter("");
  };

  const editRecommendationHandler = (recommendation) => {
    console.log(recommendation);
    setEditRecommendation({
      id: recommendation.id,
      score: recommendation.score,
      description: recommendation.description,
    });
    setEditRecommendationModal(true);
  };

  const deleteRecommendationHandler = (recommendation) => {
    setDisableButtons(true);
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BACKEND}/admin/recommendation`,
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
            data: {
              recommendation,
            },
          }
        );
        if (data === "success") {
          toast.success("Recommendation Deleted");
          setDisableButtons(false);
        } else {
          toast.error("Error deleting recommendation");
          setDisableButtons(false);
        }
      } catch (error) {
        toast.error("Error deleting recommendation");
        setDisableButtons(false);
      }
    });
  };

  useEffect(() => {
    if (recommendations === null) {
      setLoading(true);
      firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin/recommendation`,
            {
              headers: {
                Authorization: "Bearer " + idToken,
              },
            }
          );
          const sortedRecommendations = data.sort((a, b) => {
            if (a.score > b.score) return 1;
            if (a.score < b.score) return -1;
            return 0;
          });
          setRecommendations(sortedRecommendations);
          setFilteredRecommendations(sortedRecommendations);
          setActiveFilter("");
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error retrieving recommendations");
        }
      });
    }
  }, [recommendations]);

  useEffect(() => {
    if (
      !addRecommendationModal &&
      !editRecommendationModal &&
      !disableButtons
    ) {
      setLoading(true);
      firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin/recommendation`,
            {
              headers: {
                Authorization: "Bearer " + idToken,
              },
            }
          );
          const sortedRecommendations = data.sort((a, b) => {
            if (a.score > b.score) return 1;
            if (a.score < b.score) return -1;
            return 0;
          });
          setRecommendations(sortedRecommendations);
          setFilteredRecommendations(sortedRecommendations);
          setActiveFilter("");
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error retrieving recommendations");
        }
      });
    }
  }, [addRecommendationModal, editRecommendationModal, disableButtons]);

  return (
    <div className='flex flex-col'>
      <div className='flex p-2 items-center space-x-3'>
        <p>Filter Score:</p>
        <button
          className={`${
            activeFilter === "scoreOne" ? "bg-qualiscoreGreen" : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(1, "scoreOne")}
        >
          1
        </button>
        <button
          className={`${
            activeFilter === "scoreTwo" ? "bg-qualiscoreGreen" : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(2, "scoreTwo")}
        >
          2
        </button>
        <button
          className={`${
            activeFilter === "scoreThree"
              ? "bg-qualiscoreGreen"
              : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(3, "scoreThree")}
        >
          3
        </button>
        <button
          className={`${
            activeFilter === "scoreFour" ? "bg-qualiscoreGreen" : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(4, "scoreFour")}
        >
          4
        </button>
        <button
          className={`${
            activeFilter === "scoreFive" ? "bg-qualiscoreGreen" : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(5, "scoreFive")}
        >
          5
        </button>
        <button
          className={`${
            activeFilter === "scoreSix" ? "bg-qualiscoreGreen" : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(6, "scoreSix")}
        >
          6
        </button>
        <button
          className={`${
            activeFilter === "scoreSeven"
              ? "bg-qualiscoreGreen"
              : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(7, "scoreSeven")}
        >
          7
        </button>
        <button
          className={`${
            activeFilter === "scoreEight"
              ? "bg-qualiscoreGreen"
              : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(8, "scoreEight")}
        >
          8
        </button>
        <button
          className={`${
            activeFilter === "scoreNine" ? "bg-qualiscoreGreen" : "bg-slate-100"
          } p-2 w-10 rounded-lg text-center shadow-md`}
          onClick={() => scoreFilter(9, "scoreNine")}
        >
          9
        </button>
        <button
          className={`${
            activeFilter === "" ? "hidden" : "bg-slate-100"
          } p-2 w-24 rounded-lg text-center shadow-md`}
          onClick={() => clearFilters()}
        >
          Clear Filter
        </button>
      </div>
      <table className='table-auto border w-full mx-auto'>
        <thead>
          <tr className='bg-slate-700 text-white'>
            <th className='w-2/12 text-center border px-2 py-1'>Score</th>
            <th className='w-8/12 text-center border px-2 py-1'>
              Recommendation
            </th>
            <th className='w-2/12 text-center border px-2 py-1'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {recommendations &&
            filteredRecommendations?.map((row, index) => (
              <tr
                key={row.id}
                className={index % 2 ? "bg-slate-100" : "bg-slate-300"}
              >
                <td className='text-center border px-2 py-1'>{row.score}</td>
                <td className='text-left border px-2 py-1'>
                  {row.description}
                </td>
                <td className='flex justify-around text-center border px-2 py-1'>
                  <button
                    className='ml-2 px-2 py-1 bg-qualiscoreDarkBlue text-white rounded-lg shadow-lg'
                    onClick={() => editRecommendationHandler(row)}
                    disabled={disableButtons}
                  >
                    Edit
                  </button>
                  <button
                    className='ml-2 px-2 py-1 bg-red-400 text-white rounded-lg shadow-lg'
                    onClick={() => deleteRecommendationHandler(row)}
                    disabled={disableButtons}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {filteredRecommendations?.length === 0 && (
        <p className='text-center p-4'>No recommendations to display</p>
      )}
      {loading && (
        <div className='flex flex-col mt-4 w-full items-center justify-center'>
          <LoadingSpinner />
          <p>Gathering recommendations...</p>
        </div>
      )}
      {editRecommendationModal && (
        <EditRecommendationModal
          editRecommendation={editRecommendation}
          setEditRecommendation={setEditRecommendation}
          setEditRecommendationModal={setEditRecommendationModal}
        />
      )}
    </div>
  );
}

export default RecommendationsTable;
