import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import axios from "axios";
import { toast } from "react-toastify";
import TextField from "../../components/ui/TextField";
import Button from "../../components/ui/Button";
import { useDispatch } from "react-redux";
import { createInvitedUser } from "../../redux/actions/authActions";

export default function InviteScreen() {
	const { inviteId } = useParams();
	const [loaded, setLoaded] = useState(false);
	const [viewInvite, setViewInvite] = useState(null);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const dispatch = useDispatch();

	const registerHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			toast.error("Passwords do not match");
		}
		dispatch(createInvitedUser(viewInvite, password));
	};

	// Retrieve invite from backend
	useEffect(() => {
		if (!viewInvite) {
			const getData = async () => {
				try {
					const { data } = await axios.get(
						`${process.env.REACT_APP_BACKEND}/public/invite`,
						{
							params: {
								inviteId: inviteId,
							},
						}
					);
					if (data) {
						setViewInvite(data);
						setLoaded(true);
					} else {
						toast.error("No invite found");
					}
				} catch (error) {
					console.log(error);
					toast.error("Error retrieving invite");
				}
			};
			getData();
		}
	}, [viewInvite, inviteId]);

	return (
		<div className='flex flex-col min-h-screen justify-center items-center'>
			{loaded && (
				<>
					<QualiscoreLogo classNameDiv='flex w-2/12' />
					<p>
						{viewInvite.userDetails.companyDetails.companyName} have invited you
						to their team on Qualiscore!
					</p>
					<form className='flex flex-col items-center w-full md:w-4/12 mt-10'>
						<p className='mt-2 mb-4'>
							Welcome {viewInvite.userDetails.fullName}
						</p>
						<div className='flex flex-col w-full mt-2 mb-4'>
							<label className='mb-2 select-none'>Email</label>
							<input
								className='p-2 h-10 border rounded-lg bg-slate-200 text-slate-500'
								type='email'
								autoComplete='username'
								value={viewInvite.userDetails.email}
								readOnly
								disabled
							/>
						</div>
						<TextField
							label='Password (should be at least 6 characters)'
							type='password'
							placeholder='Create password'
							autoComplete='new-password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<TextField
							label='Confirm Password'
							type='password'
							placeholder='Confirm password'
							autoComplete='new-password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<Button label='Join' onClick={(e) => registerHandler(e)} />
					</form>
				</>
			)}
			{!loaded && (
				<>
					<LoadingSpinner />
					<p className='w-3/12 text-center mt-2'>
						Welcome to Qualiscore, we're just gathering your information.
					</p>
				</>
			)}
		</div>
	);
}
