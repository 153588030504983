import React from "react";
import Gauge from "../../../components/ui/gauge/Gauge";
import { useSelector } from "react-redux";

export default function QualiscoreGauge() {
	const qualiscore = useSelector((state) => state?.analytics?.data?.qualiScore);

	console.log("Quali: ", qualiscore);
	return (
		<div className='flex flex-col w-fit h-fit relative p-8'>
			{qualiscore && (
				<>
					<Gauge percent={(qualiscore / 9) * 100} radius={150} />
					<div className='flex flex-col items-center justify-center absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
						<p className='text-xl'>Your Qualiscore</p>
						<p className='text-4xl'>{qualiscore.toFixed(2)}</p>
					</div>
					<p className='absolute left-2 bottom-6'>0</p>
					<p className='absolute right-2 bottom-6'>9</p>
				</>
			)}
		</div>
	);
}
