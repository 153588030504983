import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import Button from "../../components/ui/Button";
import AdminNav from "./components/AdminNav";
import AddRecommendationModal from "./components/AddRecommendationModal";
import RecommendationsTable from "./components/RecommendationsTable";

function RecommendationsScreen() {
	const [addRecommendationModal, setAddRecommendationModal] = useState(false);

	return (
		<Layout>
			<AdminNav />
			<div className='flex flex-col px-6 py-6'>
				<h3 className='text-2xl font-bold mb-4'>Manage Recommendations</h3>
				<div className='flex'>
					<Button label='Create' onClick={() => setAddRecommendationModal(true)} />
				</div>
				<div className='mt-4'>
					<RecommendationsTable addRecommendationModal={addRecommendationModal} />
				</div>
			</div>
			{addRecommendationModal && (
				<AddRecommendationModal setAddRecommendationModal={setAddRecommendationModal} />
			)}
		</Layout>
	);
}

export default RecommendationsScreen;
