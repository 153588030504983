import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../../../utils/formattedDate";

function UsersTable({ users }) {
	const [paginationStart, setPaginationStart] = useState(0);
	const [paginationEnd, setPaginationEnd] = useState(24);
	const [paginationPage, setPaginationPage] = useState(1);

	const navigate = useNavigate();

	const totalPages = Math.ceil(users.length / 25).toFixed(0);

	const usersToDisplay = users.slice(paginationStart, paginationEnd);

	const nextPaginationHandler = () => {
		const nextPaginationStart = paginationStart + 25;
		const nextPaginationEnd = paginationEnd + 25;
		const nextPaginationPage = paginationPage + 1;

		setPaginationStart(nextPaginationStart);
		setPaginationEnd(nextPaginationEnd);
		setPaginationPage(nextPaginationPage);
	};

	const previousPaginationHandler = () => {
		const nextPaginationStart = paginationStart - 24;
		const nextPaginationEnd = paginationEnd - 25;
		const nextPaginationPage = paginationPage - 1;

		setPaginationStart(nextPaginationStart);
		setPaginationEnd(nextPaginationEnd);
		setPaginationPage(nextPaginationPage);
	};

	const viewDashboardHandler = (user) => {
		console.log(user);
		navigate(`/admin/dashboards/${user.id}`);
	};

	return (
		<div>
			<table className='table-auto border w-full mx-auto'>
				<thead>
					<tr className='bg-slate-700 text-white'>
						<th className='w-2/12 text-left border px-2 py-1'>User</th>
						<th className='w-2/12 text-center border px-2 py-1'>Company</th>
						<th className='w-2/12 text-center border px-2 py-1'>Email</th>
						<th className='w-2/12 text-center border px-2 py-1'>Tier</th>
						<th className='w-2/12 text-center border px-2 py-1'>User Since</th>
						<th className='w-2/12 text-center border px-2 py-1'>Dashboard</th>
					</tr>
				</thead>
				<tbody>
					{users &&
						usersToDisplay?.map((row, index) => (
							<tr
								key={row.id}
								className={index % 2 ? "bg-slate-100" : "bg-slate-300"}
							>
								<td className='w-2/12 text-left border px-2 py-1'>
									{row.fullName}
								</td>
								<td className='w-2/12 text-center border px-2 py-1'>
									{row.companyDetails.companyName}
								</td>
								<td className='w-2/12 text-center border px-2 py-1'>
									{row.email}
								</td>
								<td className='w-2/12 text-center border px-2 py-1'>
									{row.companyDetails.subscriptionDetails
										? row.companyDetails.subscriptionDetails.packageDetails
												.packageName
										: "Not Subscribed"}
								</td>
								<td className='w-2/12 text-center border px-2 py-1'>
									{formattedDate(row.createdAt)}
								</td>
								<td className='w-2/12 text-center border px-2 py-1'>
									<button
										onClick={() => viewDashboardHandler(row)}
										className='text-blue-700 underline disabled:text-gray-700 disabled:no-underline disabled:text-xs'
										disabled={
											!row.companyDetails.subscriptionDetails ||
											!row.companyDetails.googleLinked
										}
									>
										{!row.companyDetails.subscriptionDetails
											? "Not Subscribed"
											: !row.companyDetails.googleLinked
											? "Google Not Linked"
											: "View"}
									</button>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<div className='flex justify-end space-x-2 mt-2'>
				<button
					disabled={paginationPage === 1}
					className={paginationPage === 1 ? "invisible" : ""}
					onClick={() => previousPaginationHandler()}
				>
					&larr;
				</button>
				<p>{`Page ${paginationPage} of ${totalPages}`}</p>
				<button
					disabled={paginationPage === totalPages}
					className={paginationPage >= totalPages ? "invisible" : ""}
					onClick={() => nextPaginationHandler()}
				>
					&rarr;
				</button>
			</div>
		</div>
	);
}

export default UsersTable;
