import React from "react";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../../redux/actions/authActions";
import CloseIcon from "../../ui/CloseIcon";
import NavigationMenu from "../../NavigationMenu";

function SideNav({ setShowSideNav }) {
	const dispatch = useDispatch();

	const signOutHandler = () => {
		dispatch(signOutUser());
	};

	return (
		<div className='flex flex-col items-center justify-between fixed min-h-screen max-h-screen w-10/12 sm:w-8/12 md:hidden z-10 top-0 left-0 bg-white overflow-x-hidden pt-8'>
			<div className='flex w-full justify-end pr-8'>
				<button onClick={() => setShowSideNav(false)}>
					<CloseIcon />
				</button>
			</div>
			<NavigationMenu />
			<button
				className='w-full bg-qualiscoreDarkBlue text-white h-[54px]'
				onClick={() => signOutHandler()}
			>
				Log Out
			</button>
		</div>
	);
}

export default SideNav;
