import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../components/footer";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { createNewUser } from "../../redux/actions/authActions";

function RegisterScreen() {
	const [error, setError] = useState({
		error: false,
		message: "",
	});
	const [newUser, setNewUser] = useState({
		fullName: "",
		companyName: "",
		email: "",
		password: "",
		confirmPassword: "",
		privacyPolicy: false,
	});

	const dispatch = useDispatch();

	const registerHandler = (e) => {
		e.preventDefault();
		setError({
			error: false,
			message: "",
		});
		if (
			newUser.name === "" ||
			newUser.email === "" ||
			newUser.company === "" ||
			newUser.password === "" ||
			newUser.confirmPassword === ""
		) {
			setError({
				error: true,
				message: "Please complete all fields",
			});
			return;
		}
		if (newUser.password !== newUser.confirmPassword) {
			setError({
				error: true,
				message: "Passwords do not match",
			});
			return;
		}
		if (newUser.password.length < 6) {
			setError({
				error: true,
				message: "Password too short!",
			});
			return;
		}
		if (!newUser.privacyPolicy) {
			setError({
				error: true,
				message: "Please accept the privacy policy to continue",
			});
			return;
		}
		dispatch(createNewUser(newUser));
	};

	return (
		<div className='flex flex-col min-h-screen justify-center items-center p-2'>
			{/* Logo Here */}

			<QualiscoreLogo classNameDiv='flex w-2/12' />
			<form className='flex flex-col w-full md:w-4/12'>
				<TextField
					label='Full Name'
					type='text'
					placeholder='Enter your name'
					autoComplete='given-name'
					value={newUser.fullName}
					onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })}
				/>
				<TextField
					label='Company'
					type='text'
					placeholder='Enter company (optional)'
					autoComplete='organization'
					value={newUser.companyName}
					onChange={(e) => setNewUser({ ...newUser, companyName: e.target.value })}
				/>
				<TextField
					label='Email Address'
					type='email'
					placeholder='Enter email address'
					autoComplete='email'
					value={newUser.email}
					onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
				/>

				<TextField
					label='Password (should be at least 6 characters)'
					type='password'
					placeholder='Enter password'
					autoComplete='new-password'
					value={newUser.password}
					onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
				/>

				<TextField
					label='Confirm Password'
					type='password'
					placeholder='Confirm password'
					autoComplete='new-password'
					value={newUser.confirmPassword}
					onChange={(e) => setNewUser({ ...newUser, confirmPassword: e.target.value })}
				/>
				<div className='flex justify-center space-x-4 my-4'>
					<input
						id='policyAccept'
						type='checkbox'
						className='border'
						checked={newUser.privacyPolicy}
						onClick={() => setNewUser({ ...newUser, privacyPolicy: !newUser.privacyPolicy })}
					/>
					<label htmlFor='policyAccept'>
						I confirm I agree to the{" "}
						<a
							href='https://www.qualiscore.com/privacy-policy/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<span className='text-blue-500 underline'>Privacy Policy</span>
						</a>
					</label>
				</div>
				{error.error && <p className='text-red-500 p-2'>*{error.message}</p>}
				<Button label='Join' onClick={(e) => registerHandler(e)} />
			</form>
			<p className='mt-4'>
				Already got an account?{" "}
				<a href='/' className='text-blue-500 underline'>
					Sign In
				</a>
			</p>
			<Footer />
		</div>
	);
}

export default RegisterScreen;
