import React from "react";

function ProgressReportChart({
	urls,
	pageScoreIncrease,
	pageScoreDecrease,
	setFilteredUrls,
	setActiveFilter,
}) {
	const setFilterScoreChangeHandler = (direction) => {
		if (direction === "increased") {
			const newFilteredUrls = urls.filter((url) => url.score > url.prevScore);
			setFilteredUrls(newFilteredUrls);
			setActiveFilter("pageMovementIncreased");
		} else if (direction === "decreased") {
			const newFilteredUrls = urls.filter((url) => url.score < url.prevScore);
			setFilteredUrls(newFilteredUrls);
			setActiveFilter("pageMovementDecreased");
		}
	};

	return (
		<div className='flex flex-col items-center justify-center grow w-full p-8'>
			<p className='text-2xl 2xl:text-4xl text-center font-semibold'>Score Change - Last 30 Days</p>

			<div className='flex grow w-full justify-around items-center'>
				<button
					className='flex w-5/12 items-center justify-center'
					onClick={(e) => setFilterScoreChangeHandler("increased")}
				>
					<p className='text-7xl md:text-5xl xl:text-7xl 2xl:text-8xl'>{pageScoreIncrease}</p>
					<p className='text-7xl text-qualiscoreGreen'>&uarr;</p>
				</button>
				<button
					className='flex w-5/12 items-center justify-center'
					onClick={(e) => setFilterScoreChangeHandler("decreased")}
				>
					<p className='text-7xl md:text-5xl xl:text-7xl 2xl:text-8xl'>{pageScoreDecrease}</p>
					<p className='text-7xl text-red-400'>&darr;</p>
				</button>
			</div>
		</div>
	);
}

export default ProgressReportChart;
