import axios from "axios";
import { toast } from "react-toastify";
import { firebaseAuth } from "../../firebase";
import * as t from "../constants/toDoConstants";
import * as s from "../constants/analyticsConstants";

export const getToDos = () => async (dispatch) => {
  dispatch({
    type: t.ACTION_LOADING_TODO,
  });
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND}/todo`,
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      dispatch({
        type: t.GET_TODO,
        payload: data,
      });
      dispatch({
        type: t.ACTION_COMPLETE_TODO,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: t.ACTION_FAILED_TODO,
      });
    }
    dispatch({
      type: t.ACTION_RESET_TODO,
    });
  });
};

export const createToDos = (newToDo) => async (dispatch) => {
  dispatch({
    type: t.ACTION_LOADING_TODO,
  });
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND}/todo`,
        {
          newToDo,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      dispatch({
        type: t.CREATE_TODO,
        payload: data.CreateToDo,
      });
      dispatch({
        type: s.GET_META_DATA,
        payload: data.returnUrls,
      });
      toast.success("To Do Created");
      dispatch({
        type: t.ACTION_COMPLETE_TODO,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: t.ACTION_FAILED_TODO,
      });
      if (error.response.status === 409) {
        toast.error("This task already exists");
      } else if (error.response.data === "Task limit reached") {
        toast.warning(
          "You have reached your task limit, complete some to dos before adding more!"
        );
      } else {
        toast.error("Error creating task");
      }
    }
    setTimeout(() => {
      dispatch({
        type: t.ACTION_RESET_TODO,
      });
    }, 1000);
  });
};

export const deleteToDo = (deleteToDo) => async (dispatch) => {
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BACKEND}/todo`,
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
          data: {
            deleteToDo,
          },
        }
      );
      dispatch({
        type: t.DELETE_TODO,
        payload: data,
      });
      toast.success("Task deleted");
    } catch (error) {
      console.log(error);
      dispatch({
        type: t.ACTION_FAILED_TODO,
      });
      toast.error("Error deleting task");
    }
  });
};

export const markToDoComplete = (todo) => async (dispatch) => {
  dispatch({
    type: t.ACTION_LOADING_TODO,
  });
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND}/todo`,
        {
          todo,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      dispatch({
        type: t.COMPLETED_TODO,
        payload: data,
      });
      toast.success("Task Marked as Complete");
      dispatch({
        type: t.ACTION_COMPLETE_TODO,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: t.ACTION_FAILED_TODO,
      });
      toast.error("Error marking task as complete");
    }
    dispatch({
      type: t.ACTION_RESET_TODO,
    });
  });
};
