import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/ui/Button";
import LabelTooltip from "../../../components/ui/LabelTooltip";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import ModalTemplate from "../../../components/ui/ModalTemplate";
import { firebaseAuth } from "../../../firebase";

function EditRecommendationModal({
  editRecommendation,
  setEditRecommendation,
  setEditRecommendationModal,
}) {
  const [loading, setLoading] = useState(false);
  const updateRecommendationHandler = () => {
    setLoading(true);
    firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_BACKEND}/admin/recommendation`,
          {
            editRecommendation,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
            },
          }
        );
        if (data === "success") {
          toast.success("Recommendation Updated");
          setEditRecommendationModal(false);
        } else {
          toast.error("Error updating recommendation");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error updating recommendation");
        setLoading(false);
      }
    });
  };

  const onCloseHandler = () => {
    setEditRecommendationModal(false);
  };
  return (
    <ModalTemplate title='Edit Recommendation' onClose={onCloseHandler}>
      <div className='px-6 py-4'>
        <div className='flex justify-between items-center'>
          <LabelTooltip
            label='Score'
            message='Select score that recommendation applies to'
          />
          <select
            className='w-4/12 text-center border rounded-lg p-2'
            value={editRecommendation.score}
            onChange={(e) =>
              setEditRecommendation({
                ...editRecommendation,
                score: parseInt(e.target.value),
              })
            }
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
          </select>
        </div>
        <div className='flex flex-col mt-4'>
          <LabelTooltip
            label='Recommendation'
            message='Enter recommendation to be displayed'
          />
          <textarea
            className='border rounded-lg mt-2 p-2'
            rows={5}
            value={editRecommendation.description}
            onChange={(e) =>
              setEditRecommendation({
                ...editRecommendation,
                description: e.target.value,
              })
            }
          />
        </div>
        <div className='flex mt-4 w-full justify-end'>
          {loading ? (
            <div className='flex w-full justify-center'>
              <LoadingSpinner />
            </div>
          ) : (
            <Button
              label='Save'
              onClick={() => updateRecommendationHandler()}
            />
          )}
        </div>
      </div>
    </ModalTemplate>
  );
}

export default EditRecommendationModal;
