import React from "react";
import ToolTip from "../../components/ui/ToolTip";

function LabelTooltip({ label, message }) {
  return (
    <label className='flex'>
      {label} <ToolTip message={message} />
    </label>
  );
}

export default LabelTooltip;
