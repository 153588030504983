export const urlFilterHandler = (activeFilter, pathFilter, urls) => {
  switch (activeFilter) {
    case "scoreOne":
      const scoreOneFilteredUrls = urls.filter((url) => url.score === 1);
      return scoreOneFilteredUrls;
    case "scoreTwo":
      const scoreTwoFilteredUrls = urls.filter((url) => url.score === 2);
      return scoreTwoFilteredUrls;
    case "scoreThree":
      const scoreThreeFilteredUrls = urls.filter((url) => url.score === 3);
      return scoreThreeFilteredUrls;
    case "scoreFour":
      const scoreFourFilteredUrls = urls.filter((url) => url.score === 4);
      return scoreFourFilteredUrls;
    case "scoreFive":
      const scoreFiveFilteredUrls = urls.filter((url) => url.score === 5);
      return scoreFiveFilteredUrls;
    case "scoreSix":
      const scoreSixFilteredUrls = urls.filter((url) => url.score === 6);
      return scoreSixFilteredUrls;
    case "scoreSeven":
      const scoreSevenFilteredUrls = urls.filter((url) => url.score === 7);
      return scoreSevenFilteredUrls;
    case "scoreEight":
      const scoreEightFilteredUrls = urls.filter((url) => url.score === 8);
      return scoreEightFilteredUrls;
    case "scoreNine":
      const scoreNineFilteredUrls = urls.filter((url) => url.score === 9);
      return scoreNineFilteredUrls;
    case "path":
      const pathFilteredUrls = urls.filter((url) =>
        url.path.toLowerCase().includes(pathFilter.toLowerCase())
      );
      return pathFilteredUrls;
    case "pageMovementIncreased":
      const pageMovementIncreasedUrls = urls.filter(
        (url) => url.score > url.prevScore
      );
      return pageMovementIncreasedUrls;
    case "pageMovementDecreased":
      const pageMovementDecreasedUrls = urls.filter(
        (url) => url.score < url.prevScore
      );
      return pageMovementDecreasedUrls;
    default:
      return urls;
  }
};
