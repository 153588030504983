import React from "react";

function BurgerMenu() {
	return (
		<svg viewBox='0 0 100 80' width='40' height='40' className='cursor-pointer lg:hidden mr-4'>
			<rect width='70' height='15' style={{ fill: "black" }}></rect>
			<rect y='30' width='85' height='15' style={{ fill: "Black" }}></rect>
			<rect y='60' width='100' height='15' style={{ fill: "Black" }}></rect>
		</svg>
	);
}

export default BurgerMenu;
