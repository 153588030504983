import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import Button from "../../components/ui/Button";
import { firebaseAuth } from "../../firebase";
import { setGoogleAccount } from "../../redux/actions/analyticsActions";

function SelectAccount() {
	const [userAccounts, setUserAccounts] = useState(null);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [selectedProperty, setSelectedProperty] = useState(null);

	const dispatch = useDispatch();

	const selectAccountHandler = (selected) => {
		const selection = userAccounts.filter(
			(account) => account.name === selected
		);
		setSelectedAccount(selection[0]);
	};

	const selectPropertyHandler = (selected) => {
		const selection = selectedAccount.propertySummaries.filter(
			(property) => property.property === selected
		);
		setSelectedProperty(selection[0]);
	};

	const saveSelectionHandler = () => {
		dispatch(setGoogleAccount(selectedAccount, selectedProperty));
	};

	useEffect(() => {
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.get(
					`${process.env.REACT_APP_BACKEND}/consent/accounts`,
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);
				setUserAccounts(data.data.accountSummaries);
			} catch (error) {
				console.log(error);
			}
		});
	}, []);

	return (
		<div className='flex flex-col w-full px-8 py-4 items-center'>
			<QualiscoreLogo classNameDiv='w-4/12' classNameImage='w-full' />
			<div className='flex flex-col border px-8 pt-2 pb-8 w-6/12'>
				<label className='mb-2 p-2'>Select account to link</label>
				<select
					className='p-2 h-10 border rounded-lg mb-4'
					onChange={(e) => selectAccountHandler(e.target.value)}
				>
					<option selected disabled>
						Select one option...
					</option>
					{userAccounts?.map((account) => (
						<option key={account.name} value={account.name}>
							{account.displayName}
						</option>
					))}
				</select>
				{selectedAccount && (
					<div className='flex flex-col'>
						<label className='mb-2 p-2'>Select property to link</label>
						<select
							className='p-2 h-10 border rounded-lg mb-4'
							onChange={(e) => selectPropertyHandler(e.target.value)}
						>
							<option selected disabled>
								Select one option...
							</option>
							{selectedAccount.propertySummaries.map((property) => (
								<option key={property.property} value={property.property}>
									{property.displayName}
								</option>
							))}
						</select>
					</div>
				)}
				<Button label='Save' onClick={() => saveSelectionHandler()} />
			</div>
		</div>
	);
}

export default SelectAccount;
