import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";

function ConfirmLink() {
	const navigate = useNavigate();

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const code = params.get("code");
		const scope = params.get("scope");

		if (scope !== "https://www.googleapis.com/auth/analytics.readonly") {
			// Error handling for incorrect scope
			console.log("Scope incorrect");
			return;
		}

		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				await axios.post(
					`${process.env.REACT_APP_BACKEND}/consent/`,
					{
						code,
					},
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);
				navigate("/account-selection");
			} catch (error) {
				console.log(error);
			}
		});
	});

	return (
		<div>
			<div className='flex flex-col justify-center items-center min-h-screen'>
				<LoadingSpinner />
				<p>Gathering google accounts...</p>
			</div>
		</div>
	);
}

export default ConfirmLink;
