import React from "react";
import { NavLink } from "react-router-dom";

function ActiveNavLink({ to, label }) {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? "bg-qualiscoreGreen px-4 py-1 rounded-t-2xl w-6/12 lg:w-2/12"
          : "bg-slate-200 px-4 py-1 rounded-t-2xl w-6/12 md:w-4/12 lg:w-2/12 text-black"
      }
      to={to}
    >
      {label}
    </NavLink>
  );
}

export default ActiveNavLink;
