import axios from "axios";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import AdminNav from "./components/AdminNav";
import UsersTable from "./components/UsersTable";

function AdminPanel() {
	const [loading, setLoading] = useState(false);
	const [qualiscoreUsers, setQualiscoreUsers] = useState([]);

	useEffect(() => {
		if (qualiscoreUsers.length === 0) {
			setLoading(true);
			firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
				try {
					const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/admin/users`, {
						headers: {
							Authorization: "Bearer " + idToken,
						},
					});
					console.log(data);
					setQualiscoreUsers(data);
					setLoading(false);
				} catch (error) {
					console.log(error);
				}
			});
		}
	}, [qualiscoreUsers.length]);

	return (
		<Layout>
			<AdminNav />
			{loading ? (
				<div className='flex flex-col mt-10 w-full justify-center items-center'>
					<LoadingSpinner />
					<p className='mt-4'>Getting users</p>
				</div>
			) : qualiscoreUsers.length === 0 ? (
				<p>No users found</p>
			) : (
				<div className='flex flex-col px-6 py-6'>
					<h3 className='text-2xl font-bold mb-4'>Qualiscore Users</h3>
					<UsersTable users={qualiscoreUsers} />
				</div>
			)}
		</Layout>
	);
}

export default AdminPanel;
