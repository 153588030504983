import React from "react";

function Footer() {
	return (
		<div className='flex flex-col w-full mt-10 p-4 bg-white'>
			<p className='text-center text-blue-500 underline'>
				<a
					href='https://www.qualiscore.com/privacy-policy/'
					target='_blank'
					rel='noopener noreferrer'
				>
					Privacy Policy
				</a>
			</p>
		</div>
	);
}

export default Footer;
