import React from "react";
import ActiveNavLink from "../../../components/ui/ActiveNavLink";
import { useSelector } from "react-redux";

function SettingsNav() {
	const auth = useSelector((state) => state.auth);
	const user = auth.user;
	return (
		<div className='flex bg-white px-8 space-x-2 text-white text-center shadow-lg'>
			<ActiveNavLink to='/settings/account' label='User' />
			{user.companyAdmin && (
				<ActiveNavLink to='/settings/company' label='Company' />
			)}
			{user.companyAdmin && (
				<ActiveNavLink to='/settings/subscription' label='Subscription' />
			)}
		</div>
	);
}

export default SettingsNav;
