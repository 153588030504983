import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../../firebase";
import ProgressReportChart from "../../Dashboard/components/ProgressReportChart";
import UrlTable from "../../../features/dashboard/components/UrlTable";
import PageRankChart from "../../../features/dashboard/components/PageRankChart";

function ViewDashboard() {
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [analytics, setAnalytics] = useState(null);
	const { userId } = useParams();
	const [filteredUrls, setFilteredUrls] = useState(null);
	const [activeFilter, setActiveFilter] = useState("");

	useEffect(() => {
		if (!user) {
			setLoading(true);
			firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
				try {
					const { data } = await axios.post(
						`${process.env.REACT_APP_BACKEND}/admin/dashboard`,
						{
							userId,
						},
						{
							headers: {
								Authorization: "Bearer " + idToken,
							},
						}
					);
					console.log(data);
					setUser(data.user);
					setAnalytics(data.analytics);
					setFilteredUrls(data.analytics.urls);
					setLoading(false);
				} catch (error) {
					console.log(error);
				}
			});
		}
	}, [user, userId]);

	return (
		<Layout>
			{loading ? (
				<div className='flex flex-col mt-10 w-full justify-center items-center'>
					<LoadingSpinner />
					<p className='mt-4'>Getting user dashboard</p>
				</div>
			) : user ? (
				<>
					<div className='flex flex-col w-11/12 md:w-8/12 lg:w-5/12 mx-auto mt-4 px-4 py-4 bg-white shadow-md'>
						<p className='text-center text-2xl'>Now viewing {user.custName}'s Dashboard</p>
						<p className='text-center text-xl'>
							{user.fullName.split(" ")[0]}'s current Qualiscore is:
						</p>
						<p className='text-center text-qualiscoreGreen text-4xl font-bold'>
							{analytics.qualiScore?.toFixed(2)}
						</p>
					</div>
					<div className='flex flex-col lg:flex-row w-full lg:w-11/12 mt-8 lg:mx-auto px-2 lg:px-0 justify-around lg:justify-between'>
						<div className='flex flex-col w-full lg:w-5/12'>
							<div className='flex flex-col w-full items-center p-2 lg:p-4 justify-center bg-white shadow-lg'>
								<PageRankChart
									scores={analytics.scoresArray}
									setFilteredUrls={setFilteredUrls}
									urls={analytics.urls}
									setActiveFilter={setActiveFilter}
								/>
							</div>
						</div>
						<div className='flex flex-col w-full lg:w-5/12'>
							<div className='flex flex-col grow w-full mt-10 lg:mt-0 items-center justify-center bg-white shadow-lg'>
								<ProgressReportChart
									urls={analytics.urls}
									pageScoreIncrease={analytics.pagesScoreIncreased}
									pageScoreDecrease={analytics.pagesScoreDecreased}
									filteredUrls={filteredUrls}
									setFilteredUrls={setFilteredUrls}
									activeFilter={activeFilter}
									setActiveFilter={setActiveFilter}
								/>
							</div>
						</div>
					</div>
					<div className='flex flex-col w-full lg:w-11/12 lg:mx-auto mt-10 p-2 bg-white shadow-lg'>
						<UrlTable
							urls={analytics.urls}
							filteredUrls={filteredUrls}
							setFilteredUrls={setFilteredUrls}
							activeFilter={activeFilter}
							setActiveFilter={setActiveFilter}
							disableIgnore={true}
						/>
					</div>
				</>
			) : (
				<p>No dashboard found</p>
			)}
		</Layout>
	);
}

export default ViewDashboard;
