import React from "react";
import { usePagination, DOTS } from "../hooks/usePagination";
import {
	AiFillStepBackward,
	AiFillFastBackward,
	AiFillStepForward,
	AiFillFastForward,
} from "react-icons/ai";

export default function Pagination({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	setPageSize,
}) {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	console.log(paginationRange);

	let lastPage = paginationRange[paginationRange.length - 1];

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	// If there are no records return null
	if (totalCount === 0) {
		return null;
	}
	return (
		<div className='flex justify-between items-center px-4 mt-5'>
			{paginationRange.length > 1 && (
				<ul className='flex list-none space-x-2'>
					<button
						className='disabled:invisible text-center cursor-pointer'
						onClick={() => onPageChange(1)}
						disabled={currentPage === 1}
					>
						<AiFillFastBackward />
					</button>
					{/* Left navigation arrow */}

					<button
						className='disabled:invisible text-center cursor-pointer'
						onClick={onPrevious}
						disabled={currentPage === 1}
					>
						<AiFillStepBackward />
					</button>
					{paginationRange.map((pageNumber) => {
						// If the pageItem is a DOT, render the DOTS unicode character
						if (pageNumber === DOTS) {
							return <li className='pagination-item dots'>&#8230;</li>;
						}

						// Render our Page Pills
						return (
							<li
								className={
									currentPage === pageNumber
										? "min-w-[1.5rem] h-6  bg-blue-400 text-white text-center cursor-pointer rounded-md px-2"
										: "min-w-[1.5rem] h-6  text-center cursor-pointer"
								}
								onClick={() => onPageChange(pageNumber)}
							>
								{pageNumber}
							</li>
						);
					})}
					{/*  Right Navigation arrow */}
					<button
						className='disabled:invisible text-center cursor-pointer'
						onClick={onNext}
						disabled={currentPage === lastPage}
					>
						<AiFillStepForward />
					</button>
					<button
						className='disabled:invisible text-center cursor-pointer'
						onClick={() => onPageChange(lastPage)}
						disabled={currentPage === lastPage}
					>
						<AiFillFastForward />
					</button>
				</ul>
			)}
			<div className='ml-8 flex items-center space-x-4'>
				<p>Show records</p>
				<select
					value={pageSize}
					onChange={(e) => setPageSize(e.target.value)}
					className='border h-6 w-14 text-center'
				>
					<option value={10}>10</option>
					<option value={25}>25</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
				</select>
			</div>
		</div>
	);
}
