import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { firebaseAuth } from "../../firebase";
import {
	markNotAuthenticated,
	signInUserToken,
} from "../../redux/actions/authActions";
import { useSearchParams } from "react-router-dom";

function SuccessPage() {
	const dispatch = useDispatch();
	const [counter, setCounter] = useState(1);
	const [searchParams] = useSearchParams();

	console.log(searchParams.get("setup_intent"));

	useEffect(() => {
		if (counter === 5) {
		}
		const interval = setInterval(() => {
			setCounter(counter + 1);
			const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
				if (user) {
					dispatch(signInUserToken(user.accessToken));
				} else {
					dispatch(markNotAuthenticated());
				}
			});
			unsubscribe();
		}, 3000);
		return () => clearInterval(interval);
	}, [dispatch, counter]);

	return (
		<div className='flex flex-col min-h-screen justify-center items-center'>
			<QualiscoreLogo classNameDiv='w-4/12' />
			<p>Thank you for subscribing!</p>
			<p>You will shortly be redirected to your dashboard</p>
			<LoadingSpinner />
		</div>
	);
}

export default SuccessPage;
