import React from "react";

function TextField({
	label,
	value,
	type,
	placeholder,
	autoComplete,
	onChange,
	readOnly,
	name,
}) {
	return (
		<div className='flex flex-col w-full mt-2 mb-4'>
			<label className='mb-2 select-none' htmlFor={name}>
				{label}
			</label>
			<input
				id={name}
				className='p-2 h-10 border rounded-lg'
				value={value}
				type={type}
				placeholder={placeholder}
				autoComplete={autoComplete}
				onChange={onChange}
				readOnly={readOnly}
			/>
		</div>
	);
}

export default TextField;
