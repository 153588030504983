import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Button from "../../../components/ui/Button";

function CheckoutForm({ subscription }) {
	const stripe = useStripe();
	const elements = useElements();
	const [paymentError, setPaymentError] = useState({
		error: false,
		message: "",
	});

	const handleSubmit = async (event) => {
		event.preventDefault();

		setPaymentError({
			error: false,
			message: "",
		});

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			return;
		}

		const result = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: process.env.REACT_APP_STRIPE_RETURN_URL,
			},
		});

		if (result.error) {
			console.log(result.error.message);
			setPaymentError({
				error: true,
				message: result.error.message,
			});
		} else {
			// Customer will be redirected to return_url
		}
	};

	return (
		<div className='flex flex-col mt-4'>
			<h3 className='text-center text-2xl font-semibold'>Add Payment Method</h3>
			<div className='flex flex-col md:flex-row md:space-x-20 items-center md:items-start mt-8'>
				<div className='flex flex-col w-11/12 md:w-6/12 mb-4'>
					<p className='text-xl font-medium'>Payment for Qualiscore Subscription</p>
					<div className='flex justify-between mt-4'>
						<p>Package:</p>
						<p>{subscription.packageName}</p>
					</div>
					<div className='flex justify-between mt-4'>
						<p>Include URLs:</p>
						<p>{subscription.includedUrls}</p>
					</div>
					<div className='flex justify-between mt-4'>
						<p>Monthly Price</p>
						<p>${subscription.price / 100}</p>
					</div>
					<div className='flex flex-col mt-4'>
						<p className='text-center'>You will not be billed until your trial period ends.</p>
					</div>
					{paymentError.error && (
						<div className='flex flex-col text-center text-red-500 mt-8 bg-slate-200 p-2'>
							<p>Error with payment method setup:</p>
							<p>"{paymentError.message}"</p>
							<p className='mt-2 text-black'>
								Please try again, if error repeats please contact support.
							</p>
						</div>
					)}
				</div>
				<form onSubmit={(event) => handleSubmit(event)}>
					<PaymentElement />
					<div className='flex flex-col mt-4'>
						<Button label='Submit' disabled={!stripe} />
					</div>
				</form>
			</div>
		</div>
	);
}

export default CheckoutForm;
