import React from "react";
import Layout from "../../components/layout/Layout";
import SettingsNav from "./components/SettingsNav";

function Settings() {
	return (
		<Layout>
			<SettingsNav />
		</Layout>
	);
}

export default Settings;
