import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../components/footer";
import QualiscoreLogo from "../../components/QualiscoreLogo";
import Button from "../../components/ui/Button";
import TextField from "../../components/ui/TextField";
import { firebaseAuth } from "../../firebase";

function ForgotPassword() {
	const [email, setEmail] = useState("");

	const resetPasswordHandler = (e) => {
		e.preventDefault();
		sendPasswordResetEmail(firebaseAuth, email)
			.then(() => {
				toast.success("Password Reset Email Sent");
			})
			.catch((error) => {
				toast.error("Error occured");
			});
	};
	return (
		<div className='flex flex-col min-h-screen justify-center items-center p-2'>
			<QualiscoreLogo classNameDiv='flex w-2/12' />
			<p>Reset Password</p>
			<form className='flex flex-col w-full md:w-4/12'>
				<TextField
					label='Email Address'
					type='email'
					placeholder='Enter your email address'
					autoComplete='email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Button label='Reset Password' onClick={(e) => resetPasswordHandler(e)} />
			</form>
			<p className='mt-4'>
				Return to{" "}
				<a href='/' className='text-blue-500 underline'>
					Sign In
				</a>
			</p>
			<div className='absolute bottom-0'>
				<Footer />
			</div>
		</div>
	);
}

export default ForgotPassword;
