import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import SettingsNav from "./components/SettingsNav";
import Button from "../../components/ui/Button";
import ManageUsers from "./components/ManageUsers";

export default function CompanySettings() {
	const [activeComponent, setActiveComponent] = useState("");

	return (
		<Layout>
			<SettingsNav />
			<div className='flex px-6 py-6'>
				<div className='flex flex-col w-fit ml-2 px-8 py-4 bg-white shadow-lg space-y-4'>
					<Button
						className=''
						label='Manage Users'
						onClick={() => setActiveComponent("MANAGE-USERS")}
					/>
				</div>
				{activeComponent === "MANAGE-USERS" && <ManageUsers />}
			</div>
		</Layout>
	);
}
