import React from "react";

function ToolTipIcon({ message, icon }) {
	return (
		<div className='relative flex flex-col justify-center items-center group'>
			{icon}
			<div className='absolute bottom-0 items-center hidden mb-6 group-hover:flex flex-col'>
				<span className='relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md'>
					{message}
				</span>
				<div className='w-3 h-3 -mt-2 rotate-45 bg-gray-600'></div>
			</div>
		</div>
	);
}

export default ToolTipIcon;
