import React, { useEffect, useState } from "react";
import QualiscoreGauge from "../components/QualiscoreGauge";
import PageRankChart from "../components/PageRankChart";
import Layout from "../../../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData } from "../../../redux/actions/analyticsActions";
import { urlFilterHandler } from "../../../utils/applyFilters";
import PageScoreTable from "../components/PageScoreTable";

export default function Dashboard() {
	const analytics = useSelector((state) => state.analytics);
	const urls = analytics.data?.urls;
	const scoresArray = analytics.data?.scoresArray;
	const [activeFilter, setActiveFilter] = useState("");
	const [filteredUrls, setFilteredUrls] = useState(null);
	const [pathFilter, setPathFilter] = useState("");

	const dispatch = useDispatch();

	useEffect(() => {
		if (!analytics.loaded) {
			dispatch(getMetaData());
		}
	}, [analytics.loaded, dispatch]);

	useEffect(() => {
		if (analytics.loaded) {
			setFilteredUrls(urlFilterHandler(activeFilter, pathFilter, urls));
		}
	}, [urls, analytics.loaded, activeFilter, pathFilter]);

	return (
		<Layout loading={!analytics.loaded}>
			<div className='flex flex-col lg:flex-row items-center lg:justify-between py-8 px-4 lg:py-20 lg:px-20'>
				<QualiscoreGauge />
				<div className='h-40 w-full lg:w-5/12 mt-8 lg:mt-0'>
					<PageRankChart
						scores={scoresArray}
						setFilteredUrls={setFilteredUrls}
						urls={urls}
						setActiveFilter={setActiveFilter}
					/>
				</div>
			</div>
			<div className='flex flex-col w-full lg:w-11/12 lg:mx-auto mt-10 p-2'>
				{filteredUrls && (
					<PageScoreTable
						urls={urls}
						filteredUrls={filteredUrls}
						setFilteredUrls={setFilteredUrls}
						activeFilter={activeFilter}
						setActiveFilter={setActiveFilter}
						pathFilter={pathFilter}
						setPathFilter={setPathFilter}
					/>
				)}
			</div>
		</Layout>
	);
}
