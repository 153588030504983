import * as t from "../constants/analyticsConstants";
import * as s from "../constants/authConstants";
import axios from "axios";
import { firebaseAuth } from "../../firebase";

// Once user selects the account and property to link below sends data to backend and database
export const setGoogleAccount = (account, property) => async (dispatch) => {
	firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
		try {
			const { data } = await axios.post(
				`${process.env.REACT_APP_BACKEND}/consent/accounts`,
				{
					account,
					property,
				},
				{
					headers: {
						Authorization: "Bearer " + idToken,
					},
				}
			);
			dispatch({
				type: s.SIGN_IN_USER,
				payload: data.updatedUser,
			});
		} catch (error) {
			console.log(error);
		}
	});
};

// retrieves the URLs and scores from backend. Backend responds with URLs from database,
// if it has been more than 24 hours since last called then backend will refresh these from google first
export const getMetaData = () => async (dispatch) => {
	dispatch({
		type: t.ACTION_LOADING_ANALYTICS,
	});
	firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACKEND}/data/`,
				{
					headers: {
						Authorization: "Bearer " + idToken,
					},
				}
			);
			dispatch({
				type: t.GET_META_DATA,
				payload: data,
			});
			dispatch({ type: t.ACTION_COMPLETE_ANALYTICS });
		} catch (error) {
			console.log(error);
			dispatch({
				type: t.ACTION_FAILED_ANALYTICS,
			});
		}
		dispatch({
			type: t.ACTION_RESET_ANALYTICS,
		});
	});
};

// Update meta data state from one off calls
export const updateMetaData = (data) => async (dispatch) => {
	dispatch({
		type: t.GET_META_DATA,
		payload: data,
	});
};
