import React from "react";
import { toast } from "react-toastify";
import { AiOutlineUserDelete } from "react-icons/ai";
import { GrUserAdmin } from "react-icons/gr";
import ToolTipIcon from "../../../components/ui/ToolTipIcon";
import { firebaseAuth } from "../../../firebase";
import axios from "axios";

export default function UserTable({ users, setCompanyUsers }) {
	const totalAdmin = users.filter((user) => user.companyAdmin).length;

	const deleteUserHandler = (row) => {
		if (row.companyAdmin && totalAdmin === 1) {
			toast.error("Only one admin remains, please create another first.");
			return;
		}
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.delete(
					`${process.env.REACT_APP_BACKEND}/company/users`,
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
						data: {
							deleteUser: row,
						},
					}
				);
				let filteredUsers = users.filter((user) => user.id !== data.id);
				setCompanyUsers(filteredUsers);
				toast.success("User deleted");
			} catch (error) {
				toast.error("Error deleting user, please try again");
			}
		});
	};
	return (
		<div>
			<table className='table-auto border w-full mx-auto'>
				<thead>
					<tr className='bg-slate-700 text-white'>
						<th className='text-left border px-2 py-1'>User</th>
						<th className='text-center border px-2 py-1'>Email</th>
						<th className='text-center border px-2 py-1'>Company Admin</th>
						<th className='text-center border px-2 py-1'>Status</th>
						<th className='text-center border px-2 py-1'>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users &&
						users?.map((row, index) => (
							<tr
								key={row.id}
								className={index % 2 ? "bg-slate-100" : "bg-slate-300"}
							>
								<td className='text-left border px-2 py-1'>{row.fullName}</td>
								<td className='text-center border px-2 py-1'>{row.email}</td>
								<td className='text-center border px-2 py-1'>
									{row.companyAdmin ? "Yes" : "No"}
								</td>
								<td className='text-center border px-2 py-1'>
									{row.firebaseId ? "Active" : "Invited"}
								</td>
								<td className='flex items-center justify-around px-2 py-1'>
									{(!totalAdmin === 1 || !row.companyAdmin) && (
										<button onClick={() => deleteUserHandler(row)}>
											<ToolTipIcon
												icon={<AiOutlineUserDelete size={20} />}
												message='Delete User'
											/>
										</button>
									)}
									{!row.companyAdmin && (
										<button>
											<ToolTipIcon
												icon={<GrUserAdmin size={20} />}
												message='Set as Admin'
											/>
										</button>
									)}
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}
