import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { firebaseAuth } from "../../../firebase";
import RecommendationsModal from "./RecommendationsModal";
import Pagination from "../../../components/Pagination";

function UrlTable({
	urls,
	disableIgnore,
	filteredUrls,
	setFilteredUrls,
	activeFilter,
	setActiveFilter,
	setPathFilter,
}) {
	const [currentPage, setCurrentPage] = useState(1);
	const [ignoreDisable, setIgnoreDisable] = useState(false);
	const [showRecommendations, setShowRecommendations] = useState(false);
	const [recommendationsToShow, setRecommendationsToShow] = useState(null);
	const [pathSearch, setPathSearch] = useState("");
	const [urlsToDisplay, setUrlsToDisplay] = useState([]);
	const [pageSize, setPageSize] = useState(10);

	const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		return urlsToDisplay.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, pageSize, urlsToDisplay]);

	const dispatch = useDispatch();

	const viewRecommendationsHandler = (path) => {
		setRecommendationsToShow(path);
		setShowRecommendations(true);
	};

	const ignorePageHandler = (row) => {
		setIgnoreDisable(true);
		firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
			try {
				const { data } = await axios.post(
					`${process.env.REACT_APP_BACKEND}/url/ignore`,
					{
						row,
					},
					{
						headers: {
							Authorization: "Bearer " + idToken,
						},
					}
				);

				dispatch({
					type: "GET_META_DATA",
					payload: data,
				});
				toast.success("URL Ignored");
				setFilteredUrls(data.urls);
				setIgnoreDisable(false);
			} catch (error) {
				console.log(error);
				toast.error("Error ignoring URL, please try again");
				setIgnoreDisable(false);
				return false;
			}
		});
	};

	const scoreFilter = (score, filter) => {
		const newFilteredUrls = urls.filter((url) => url.score === score);
		setFilteredUrls(newFilteredUrls);
		setActiveFilter(filter);
		setCurrentPage(1);
	};

	const pathFilterHandler = () => {
		const newFilteredUrls = urls.filter((url) =>
			url.path.toLowerCase().includes(pathSearch.toLowerCase())
		);
		setFilteredUrls(newFilteredUrls);
		setActiveFilter("path");
		setPathFilter(pathSearch);
		setCurrentPage(1);
	};

	const clearFilters = () => {
		setFilteredUrls(urls);
		setActiveFilter("");
		setPathSearch("");
		setCurrentPage(1);
	};

	useEffect(() => {
		setUrlsToDisplay(filteredUrls);
	}, [filteredUrls]);

	return (
		<div className='flex flex-col items-end'>
			<div className='flex p-2 items-center space-x-3 w-full'>
				<p>Filter Score:</p>
				<button
					className={`${
						activeFilter === "scoreOne" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(1, "scoreOne")}
				>
					1
				</button>
				<button
					className={`${
						activeFilter === "scoreTwo" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(2, "scoreTwo")}
				>
					2
				</button>
				<button
					className={`${
						activeFilter === "scoreThree" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(3, "scoreThree")}
				>
					3
				</button>
				<button
					className={`${
						activeFilter === "scoreFour" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(4, "scoreFour")}
				>
					4
				</button>
				<button
					className={`${
						activeFilter === "scoreFive" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(5, "scoreFive")}
				>
					5
				</button>
				<button
					className={`${
						activeFilter === "scoreSix" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(6, "scoreSix")}
				>
					6
				</button>
				<button
					className={`${
						activeFilter === "scoreSeven" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(7, "scoreSeven")}
				>
					7
				</button>
				<button
					className={`${
						activeFilter === "scoreEight" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(8, "scoreEight")}
				>
					8
				</button>
				<button
					className={`${
						activeFilter === "scoreNine" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-10 rounded-lg text-center shadow-md`}
					onClick={() => scoreFilter(9, "scoreNine")}
				>
					9
				</button>
				<p>Path Search:</p>
				<input
					className='border p-2 w-4/12'
					value={pathSearch}
					onChange={(e) => setPathSearch(e.target.value)}
				/>
				<button
					onClick={() => pathFilterHandler()}
					className={`${
						activeFilter === "path" ? "bg-qualiscoreGreen" : "bg-slate-100"
					} p-2 w-20 rounded-lg text-center shadow-md`}
				>
					Search
				</button>
				<button
					className={`${
						activeFilter === "" ? "hidden" : "bg-slate-100"
					} p-2 w-24 rounded-lg text-center shadow-md`}
					onClick={() => clearFilters()}
				>
					Clear Filter
				</button>
			</div>
			{urlsToDisplay && (
				<table className='table-auto border w-full mx-auto no-scrollbar'>
					<thead>
						<tr className='bg-slate-700 text-white'>
							<th className='w-8/12 text-left border px-2 py-1 overflow-x-scroll no-scrollbar'>
								Page URL
							</th>
							<th className='w-1/12 text-center border'>Current Score</th>
							<th className='w-1/12 text-center border px-2 py-1'>Score Change</th>
							<th className='w-1/12 text-center border px-2 py-1'>Recommendations</th>
							{!disableIgnore && <th className='w-1/12 text-center border'>Actions</th>}
						</tr>
					</thead>
					<tbody>
						{urls &&
							currentTableData.map((row, index) => (
								<tr key={row.id} className={index % 2 ? "bg-slate-100" : "bg-slate-300"}>
									<td className=' max-w-sm border px-2 py-1 overflow-x-scroll no-scrollbar whitespace-nowrap'>
										{row.path}
									</td>
									<td className='w-1/12 text-center border'>{row.score}</td>
									<td className='w-1/12 text-center border'>
										{row.score - row.prevScore > 0 ? (
											<span className='text-qualiscoreGreen'>
												{row.score - row.prevScore}&uarr;
											</span>
										) : row.score - row.prevScore < 0 ? (
											<span className='text-red-500'>
												{Math.abs(row.score - row.prevScore)}&darr;
											</span>
										) : (
											<span>{row.score - row.prevScore}</span>
										)}
									</td>
									<td className='w-1/12 text-center border px-2 py-1'>
										{row.score === 9 ? (
											<p className='bg-qualiscoreGreen p-2 w-8/12 mx-auto rounded-lg text-white font-semibold'>
												Optimized
											</p>
										) : row.recommendations ? (
											<button
												onClick={() => viewRecommendationsHandler(row)}
												className='text-blue-700 underline'
											>
												View
											</button>
										) : (
											"No recommendations yet"
										)}
									</td>
									{!disableIgnore && (
										<td className='flex w-1/12 p-2 text-center'>
											<div className='flex w-10/12 mx-auto'>
												<input
													type='checkbox'
													disabled={ignoreDisable}
													onClick={() => ignorePageHandler(row)}
												/>
												<label className='ml-4'>Ignore</label>
											</div>
										</td>
									)}
								</tr>
							))}
					</tbody>
				</table>
			)}
			{currentTableData?.length === 0 && (
				<p className='w-full text-center p-4'>No pages to display</p>
			)}
			<Pagination
				onPageChange={(page) => setCurrentPage(page)}
				totalCount={filteredUrls.length}
				currentPage={currentPage}
				pageSize={pageSize}
				setPageSize={setPageSize}
			/>
			{showRecommendations && (
				<div className='overscroll-none'>
					<RecommendationsModal
						setShowRecommendations={setShowRecommendations}
						recommendationsToShow={recommendationsToShow}
						activeFilter={activeFilter}
						setActiveFilter={setActiveFilter}
					/>
				</div>
			)}
		</div>
	);
}

export default UrlTable;
