import React from "react";

function CheckField({ label, hideLabel, value, onChange, name }) {
	return (
		<div className='flex h-12 items-center w-fit mb-4'>
			{!hideLabel && (
				<label className='mr-4 select-none' htmlFor={name}>
					{label}
				</label>
			)}
			<input
				id={name}
				className='select-none'
				type='checkbox'
				checked={value}
				onChange={onChange}
			/>
		</div>
	);
}

export default CheckField;
